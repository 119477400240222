/**
 * 営業所設定ページ用のスクリプト
 */
 if (is_route('section.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        section_event_set();
        company_local_app_key_event();
    });
}
/**
 * ページ読み込み時のイベント設定
 */
window.section_event_set = function() {

    /*---- 設定表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-setting-modal]', function () {
        _modal.create(this, 'setting-modal', ['id']);
    });

    /*---- 設定の保存時 ----*/

    // 設定保存時
    $(document).on('click', '[data-save-modal]', function () {
        _modal.submit(this, 'save-modal', function(results) {
            let keys = Object.keys(results);
            if (keys.find(key => key == 'company')) {
                $('[data-replace-name-company-id=' + results.company.id + ']').text(results.company.name);   
            }
            if (keys.find(key => key == 'section')) {
                $('[data-replace-name-section-id=' + results.section.id + ']').text(results.section.name);   
            }
        });
    });

    /*---- 削除確認時 ----*/

    // 削除確認時
    $(document).on('click', '[data-confirm-modal]', function () {
        _modal.create(this, 'confirm-modal', ['id']);
    });

    /*---- 削除時 ----*/

    // 削除実行時
    $(document).on('click', '[data-delete-modal]', function () {
        _modal.submit(this, 'delete-modal');
    });

    /*---- CSV雛形ダウンロード時 ----*/

    // CSV雛形ダウンロード時
    $(document).on('click', '#search-form-action_download', function () {
        // アクションの取得
        let action = extend_url_query($(this).data('action'));
        // 検索フォームを配列化
        let datas = $('#search-form').serializeArray();
        // ポスト送信を実行
        post(action, datas);
    });

    /*---- CSVアップロード表示時 ----*/

    // CSVアップロード表示時
    $(document).on('click', '#search-form-action_upload', function () {
        // 初期化処理
        $('#section-csv-setting-modal-csv-submit-message').addClass('display-none');
        $('#section-csv-setting-modal-csv-submit-message').empty();
        $('#section-csv-setting-modal-csv-error').addClass('display-none');
        $('#section-csv-setting-modal-csv-error').empty();
        $('#section-csv-setting-modal-csv').val('');
    });

    /*---- CSVアップロード確認時 ----*/

    // CSVアップロード確認時
    $(document).on('change', '#section-csv-setting-modal-csv', function () {
        let modalId = '#section-csv-setting-modal';
        let formId = '#section-csv-setting-modal-form';
        let action = $(formId).data('confirm-action');
        let datas = new FormData($(formId).get(0));
        $(modalId + '-csv-error').addClass('display-none');
        $(modalId + '-csv-error').empty();
        $(modalId + '-csv-submit-message').addClass('display-none');
        $(modalId + '-csv-submit-message').empty();
        $("[data-csv-save-modal]").prop("disabled", true);
        // 送信
        _ajax.file(action, datas, function (results) {
            $(modalId + '-csv-submit-message').removeClass('display-none');
            $(modalId + '-csv-submit-message').text(results.messages[0]);
            $("[data-csv-save-modal]").prop("disabled", false);
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
            $(this).val('');
            // フォームにエラーメッセージを配置
            $.each(xhr.responseJSON.errors, function(name, message) {
                $(modalId + '-csv-error').removeClass('display-none');
                $(modalId + '-csv-error').append($('<li></li>').text(message));
            });
        });
    });

    /*---- CSVアップロード時 ----*/

    // 設定表示時
    $(document).on('click', '[data-csv-save-modal]', function () {
        let modalId = '#section-csv-setting-modal';
        let formId = '#section-csv-setting-modal-form';
        let action = $(formId).attr('action');
        let datas = new FormData($(formId).get(0));
        // 送信前にエラーをリセット
        _error.reset()
        // 送信
        _ajax.file(action, datas, function (results) {
            // 完了メッセージをセットする
            _complete.set(results);
            // モーダルを閉じる
            _modal.hide(modalId);
            // コンテンツの読込
            load_contents();
        }, function(xhr, status, errorThrown){
            // エラーをセットする
            _error.omitted(xhr, status, errorThrown);
        });
    });

    $(document).on('click', '.transfered .btn-group', function (event) {
        event.preventDefault();
    });

    /*---- メールアドレス追加 ----*/

    // メールアドレス追加ボタン押下時
    $(document).on('click', '#section-setting-modal button[name="add_email"]', function () {
        var count = $('#section-setting-modal .email-area').length;
        var area = $('#section-setting-modal .email-area:first').clone();

        area.find('input').each(function() {
            $(this).val('');
            var id = $(this).attr('id');
            var newId = id.replace(/_0/g , '_' + count);
            var label = area.find('label[for="' + id + '"]');
            var error = area.find('#' + id + '-errors');

            // ID書き換え
            $(this).attr('id', newId);
            label.attr('for', newId);

            // error初期化
            if (error.length > 0) {
                error.attr('id', newId + '-errors');
                error.find('span').html('');
            }
        });

        area.find('button').each(function() {
            $(this).prop('disabled', false);
        });

        // 最後に追加
        area.insertAfter('#section-setting-modal .email-area:last');

        // // 5件になったら追加ボタン殺す
        // if (count + 1 >= 5) {
        //     $(this).prop('disabled', true);
        // }
    });

    // メールアドレス削除ボタン押下時
    $(document).on('click', '.delete-email', function () {
        $(this).closest('.email-area').remove();
    });
}
