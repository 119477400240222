window.clearSelect = function(elm, find = '') {
    let clear = {
        init: function(select) {
            let option = {};
            // modal内の時は親modal要素をdropdownParent指定する
            let parent = $(select).parents('.modal-content');
            if (parent.length > 0) {
                option.dropdownParent = parent;
            }
            $(select).empty();
            // select2クリア
            if ($(select).hasClass('select2')) {
                option.width = "100%";
                option.data = [{'id': '', 'text': '-----'}];
                option.val = '';
                $(select).select2(option);
            }
            // multi-selectクリア
            if ($(select).hasClass('multi-select')) {
                $(select).multipleSelect('refreshOptions', {filter: false});
                $(select).multipleSelect('setSelects', []);
            }
        }
    }
    // 指定選択肢をクリア
    let select = $(elm).parents('form').find(find);
    if (select.length > 0) {
        clear.init(select);
    }
    // 営業所に紐づく選択肢は全てクリアする
    if (find !== 'select.section-select' && find !== '') {
        return;
    }
    selects = [
        'select.account-user-select',
        'select.user-select',
        'select.driver-select',
        'select.rollcall-assistant-select',
        'select.rollcall-enforcer-select',
        'select.rollcall-authorizer-select',
        'select.rollcall-remote_rolrecognizedlcall-select',
        'select.rollcall-general-select',
        'select.maintenance-manager-select',
        'select.maintenance-assistant-select',
        'select.maintenance-manager-and-assistant-select',
        'select.employment-select',
        'select.occupation-select',
        'select.post-select',
        'select.department-select',
        'select.vehicle-select',
        'select.vehicle_serial-select',
        'select.inspection_type-select',
        'select.request_destination-select',
        'select[name="vehicle_equipment_id"]',
        'select.vehicle_classification-select',
        'select[name="guidance_kind_id"]',
        'select[name="belongings"]',
        'select.repair-select',
        'select[name="consignor_id"]',
    ]
    selects.forEach(function(value){
        let select = $(elm).parents('form').find(value);
        if (select.length > 0) {
            clear.init(select);
            if(value == 'select.repair-select') {
                for(let i = 0; i < select.length; i++) {
                    $(select[i]).empty();
                    let option = {
                        width: "100%",
                        data: [{'id': '', 'text': '-----'}],
                        val: ''
                    };
                    //optionの内容を保存
                    $('#option-holder').text(JSON.stringify(option));

                    // modal内の時は親modal要素をdropdownParent指定する
                    let parent = $(select[i]).parents('.modal-content');
                    if (parent.length > 0) {
                        option.dropdownParent = parent;
                    }
                    $(select[i]).select2(option);
                }
            } else {
                $(select).empty();
                let option = {
                    width: "100%",
                    data: [{'id': '', 'text': '-----'}],
                    val: ''
                };
                // modal内の時は親modal要素をdropdownParent指定する
                let parent = $(select).parents('.modal-content');
                if (parent.length > 0) {
                    option.dropdownParent = parent;
                }
                $(select).select2(option);
            }
        }
    })
}

// 送信するデータを作成する
window.setSendData = function(datas, elm, find) {
    let select = $(elm).parents('form').find(find);
    if (select.length > 0) {
        const exclude = [
            'with_trashed', 'exclude', 'excludeStatuses'
        ];
        const include = [
            'include'
        ];
        // 変更対象のexclude・includeのdata属性を加える
        $.each($(select).data(), function(key, val) {
            if (exclude.indexOf(key) !== -1) {
                datas[key] = val;
            }
            if (include.indexOf(key) !== -1) {
                datas[key] = val;
            }
        });
        // トリガーとなるセレクトがholding,company,sectionのセレクトの場合はその値を追加する
        $.each(['holding', 'company', 'section'], function(key, val) {
            if ($(elm).hasClass(val + '-select')) {
                datas[val + '_id'] = $(elm).val();
            }
        });
        // マスタと異なるの表示名のグループ会社・会社・営業所選択ボックスを変更したとき
        if (find == 'select.holding-select' || find == 'select.company-select' || find == 'select.section-select' || find == 'select.implement-section-select') {
            let target = $(elm).parents('form').find(find);
            datas['display_id'] = $(target).data('display_id');
            datas['display_name'] = $(target).data('display_name');
        }
        // account-user-selectの場合は選択中の値を追加する
        if (find === 'select.account-user-select') {
            datas.selected = $(select).val();
        }
        // vehicle-selectもしくはcompany-vehicle-selectで「廃車を含む」チェックボックス要素が存在・チェックがあれば、excludeStatusesにマージ
        if (find === 'select.vehicle-select' || find === 'select.company-vehicle-select') {
            let flgElm = $(select).prev('.include-scrap-vehicle-flg').find('input[name=include_scrap_vehicle_flg]');
            if (flgElm.length) {
                let scrapStatus = flgElm.data('scrap_status');
                if (!flgElm.prop('checked')) {
                    // 廃車を含まない = 除外ステータスを追加
                    if (datas['excludeStatuses']) {
                        datas['excludeStatuses'] = datas['excludeStatuses'] + ',' + scrapStatus;
                    } else {
                        datas['excludeStatuses'] = scrapStatus;
                    }
                }
            }
        }
    }
    return datas;
}

// セレクトの値をセットする
window.setSelect = function(elm, find, datas = {}, callback = null) {
    // multi-select 設定の時は、setMultiSelect
    if ($(elm).parents('form').find(find).hasClass('multi-select')) {
        setMultiSelect(elm, find, datas, callback);
        return;
    }
    let select = $(elm).parents('form').find(find);
    if (select.length == 0) {
        select = $(elm).parents('.dummy-form-for-set-select').find(find);
    }
    if (select.length > 0) {
        let action = $(select).data('action');
        let value = $(select).val();
        datas = setSendData(datas, elm, find);
        _ajax.post(action, datas, function (results) {
            if(find == 'select.repair-select') {
                for(let i = 0; i < select.length; i++) {
                    $(select[i]).empty();
                    let option = {
                        width: "100%",
                        data: results.options,
                        val: value
                    };
                    //optionの内容を保存
                    $('#option-holder').text(JSON.stringify(option));

                    // modal内の時は親modal要素をdropdownParent指定する
                    let parent = $(select[i]).parents('.cards');
                    if (parent.length > 0) {
                        option.dropdownParent = parent;
                    }
                    $(select[i]).select2(option);
                    $(select[i]).val(value).trigger('change');
                    if (callback != null) {
                        callback(elm);
                    }
                }
            } else {

                $(select).empty();
                // 変更前の名前が存在する場合
                if ($(select).is('[data-original_name]') && $(select).data('original_name') !== '') {
                    results.options.forEach(function(item) {
                        if (item.id === $(select).data('default-id')) {
                          item.text = $(select).data('original_name');
                        }
                    });
                }
                let option = {
                    width: "100%",
                    data: results.options,
                    val: value
                };
                // modal内の時は親modal要素をdropdownParent指定する
                let parent = $(select).parents('.modal-content');
                if (parent.length > 0) {
                    option.dropdownParent = parent;
                }
                $(select).select2(option);
                $(select).val(value).trigger('change');
                if (callback != null) {
                    callback(elm);
                }

            }
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    }
}

// セレクトの値をセットする
window.setMultiSelect = function(elm, find, datas = {}, callback = null) {
    let select = $(elm).parents('form').find(find);
    datas.multi = true;
    if (select.length > 0) {
        let action = $(select).data('action');
        let value = $(select).val();
        datas = setSendData(datas, elm, find);
        _ajax.post(action, datas, function (results) {
            $(select).empty();
            $.each(results.options, function(index, option) {
                $(select).append($('<option></option>', {
                    'value': option.id
                }).text(option.text));
            })
            $(select).multipleSelect('refreshOptions', {
                filter: false
            });
            $(select).multipleSelect('setSelects', []);
            if (callback != null) {
                callback(elm, select, value);
            }
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    }
}

// セレクトの選択値をセットする
window.setMultiSelected = function(elm, find, datas = {}) {
    let select = $(elm).parents('form').find(find);
    datas.multi = true;
    if (select.length > 0) {
        let action = $(select).data('selected-action');
        _ajax.post(action, datas, function (results) {
            if (results.value != null) {
                $(select).multipleSelect('setSelects', results.value);
            } else {
                $(select).multipleSelect('setSelects', []);
            }
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    }
}

// チェックボックスの値をセットする
window.setOccupationCheckbox = function(elm, datas = {}) {
    let checkbox = $(elm).parents('form').find('.occupation-check');
    if (checkbox.length > 0) {
        let action = $(checkbox).data('action');
        _ajax.post(action, datas, function (results) {
            let index = results.options.findIndex((v) => v.id == '');
            if (index == 0) {
                results.options.splice(index, 1);
            }
            $(checkbox).children('div:not(.copy,.occupation-check-not-found)').remove();
            if (results.options.length > 0) {
                $.each(results.options, function(index, option) {
                    let clone = $(checkbox).find('.copy').clone();
                    let input = $(clone).find('input');
                    let label = $(clone).find('label');
                    let id = $(input).attr('id') + option.id;
                    $(input).attr('id', id);
                    $(input).val(option.id);
                    $(input).data('occupation-type', option.type);
                    $(label).attr('for', id);
                    $(label).text(option.text);
                    $(clone).removeClass('copy').removeClass('display-none');
                    $(checkbox).append($(clone));
                });
                $(checkbox).find('.occupation-check-not-found').addClass('display-none');
            } else {
                $(checkbox).find('.occupation-check-not-found').removeClass('display-none');
            }
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    }
}

// ユーザーとドライバーのセレクト設定時の共通処置
window.commonUserSelectedEvent = function(elm) {
    let sectionSelect = $(elm).parents('form').find('.section-select');
    let datas = {};
    if (sectionSelect.length > 0) {
        datas.user_id = $(elm).val();
        datas.section_id = $(sectionSelect).val();
    }
    setSelect(elm, 'select.vehicle-select', datas);
    setSelect(elm, 'select.vehicle_serial-select', datas);
    datas.type = 'authorizer';
    setSelect(elm, 'select.rollcall-authorizer-select', datas);
    setSelect(elm, 'select.rollcall-recognized-select', datas);
}

/**
 * Ajax系セレクトのオプション設定を行う
 */
$(function () {

    /*---- グループ会社のセレクト設定時 ----*/

    // セレクト設定時
    $(document).on('change', 'select.holding-select', function () {
        setOccupationCheckbox(this);
        if ($(this).find('option:selected').length > 0 && $(this).find('option:selected').val() !== '') {
            setSelect(this, 'select.company-select');
            setSelect(this, 'select.contract-select');
            setMultiSelect(this, 'select.group-select');
            setSelect(this, 'select.vehicle_model-select');
            setSelect(this, 'select.company-driver-select.search-driver', {'type': 'driver'});
            setSelect(this, 'select.repair-select');
        } else {
            clearSelect(this, 'select.contract-select');
            clearSelect(this, 'select.company-select');
            clearSelect(this, 'select.section-select');
            clearSelect(this, 'select.implement-section-select');
            clearSelect(this, 'select.company-driver-select');
            clearSelect(this, 'select.request_destination-select');

            // 会社driverが検索要素設定の時は、クリア時に全ドライバーリスト取得
            if ($(this).hasClass('search-driver')) {
                setSelect(this, 'select.company-driver-select', {'type': 'driver'});
            }
        }
    });

    /*---- 会社のセレクト設定時 ----*/

    // セレクト設定時
    $(document).on('change', 'select.company-select', function () {
        setOccupationCheckbox(this);
        if ($(this).find('option:selected').length > 0 && $(this).find('option:selected').val() !== '') {
            setSelect(this, 'select.contract-select');
            setSelect(this, 'select.section-select', {'section_ids': $(this).parents('form').find('select.section-select').data('section_ids')});
            setSelect(this, 'select.implement-section-select');
            setSelect(this, 'select.notificationsection-select');
            setSelect(this, 'select.company-driver-select', {'type': 'driver'});
            setSelect(this, 'select.company-vehicle-select');
            setSelect(this, 'select.company-vehicle_serial-select');
            setSelect(this, 'select.repair-select');
            setSelect(this, 'select.rollcall-company-authorizer-select', {'type': 'authorizer'});
            setSelect(this, 'select.company-maintenance-manager-and-assistant-select', {'type': 'maintenance_manager_and_assistant'});
            setSelect(this, 'select[name="loading_point_id"]', {'type': 1});
            setSelect(this, 'select[name="unloading_point_id"]', {'type': 2});
            setSelect(this, 'select[name="delivery_item_id"]');
            setMultiSelect(this, 'select.belonging-multiple-select', {
                'rollcall_type': (
                    ($(this).parents('form').find('input[name="rollcall_type"]:checked').length) ? 
                        $(this).parents('form').find('input[name="rollcall_type"]:checked').val() :
                        $(this).parents('form').find('input[name="rollcall_type"]').val()
                ),
                'default_belongings': $(this).parents('form').find('select[name="belongings[]"]').data('default-belongings'),
            }, function(companySelect, select, values) {
                $.each(values, function(i, value) {
                    $(select).multipleSelect('check', value);
                });
            });
        } else {
            clearSelect(this, 'select.contract-select');
            clearSelect(this, 'select.section-select');
            clearSelect(this, 'select.implement-section-select');
            clearSelect(this, 'select.notificationsection-select');
            clearSelect(this, 'select.company-driver-select');
            clearSelect(this, 'select.company-vehicle-select');
            clearSelect(this, 'select.company-vehicle_serial-select');
            clearSelect(this, 'select.request_destination-select');
            clearSelect(this, 'select.rollcall-company-authorizer-select');
            clearSelect(this, 'select.company-maintenance-manager-and-assistant-select');
            clearSelect(this, 'select[name="loading_point_id"]');
            clearSelect(this, 'select[name="unloading_point_id"]');
            clearSelect(this, 'select[name="delivery_item_id"]');
            clearSelect(this, 'select.belonging-multiple-select');
            clearSelect(this, 'select.instruction-multiple-select');

            // 会社driverが検索要素設定の時は、クリア時にholding_idでドライバーリスト取得
            if ($(this).hasClass('search-driver')) {
                let holding = $('select.holding-select');
                if (holding.find('option:selected').val().length > 0 && holding.find('option:selected').val() !== '') {
                    setSelect(this, 'select.company-driver-select', {'type': 'driver', 'holding_id': holding.find('option:selected').val()});
                }
            }
        }
    });

    /*---- 営業所のセレクト設定時 ----*/

    // セレクト設定時
    $(document).on('change', 'select.section-select', function () {
        setOccupationCheckbox(this, {'section_id': $(this).val()});
        if ($(this).find('option:selected').length > 0 && $(this).find('option:selected').val() !== '') {
            setSelect(this, 'select.account-user-select', {'type': 'account'});
            setSelect(this, 'select.user-select');
            setSelect(this, 'select.driver-select', {'type': 'driver'});
            setSelect(this, 'select.rollcall-assistant-select', {'type': 'assistant'});
            setSelect(this, 'select.rollcall-enforcer-select', {'type': 'enforcer'});
            setSelect(this, 'select.rollcall-authorizer-select', {'type': 'authorizer'});
            setSelect(this, 'select.rollcall-recognized-select', {'type': 'recognized'});
            setSelect(this, 'select.rollcall-general-select', {'type': 'general'});
            setSelect(this, 'select.maintenance-manager-select', {'type': 'maintenance_manager'});
            setSelect(this, 'select.maintenance-assistant-select', {'type': 'maintenance_assistant'});
            setSelect(this, 'select.maintenance-manager-and-assistant-select', {'type': 'maintenance_manager_and_assistant'});
            setSelect(this, 'select.employment-select');
            setSelect(this, 'select.occupation-select');
            setMultiSelect(this, 'select.occupation-multiple-select', {'section_id': $(this).val()});
            setMultiSelect(this, 'select.instruction-multiple-select', {
                'section_id': $(this).val(),
                'authorizer_id': $(this).parents('form').find('select[name="authorizer_id"]').val(),
                'rollcall_type': (
                    ($(this).parents('form').find('input[name="rollcall_type"]:checked').length) ? 
                        $(this).parents('form').find('input[name="rollcall_type"]:checked').val() :
                        $(this).parents('form').find('input[name="rollcall_type"]').val()
                ),
                'user_id': $(this).parents('form').find('select[name="user_id"]').val(),
                'default_instructions': $(this).parents('form').find('select[name="new_instructions[]"]').data('default-instructions'),
            }, function(companySelect, select, values) {
                $.each(values, function(i, value) {
                    $(select).multipleSelect('check', value);
                });
            });
            setSelect(this, 'select.post-select');
            setSelect(this, 'select.department-select');
            setSelect(this, 'select.inspection_type-select');
            setSelect(this, 'select.vehicle_classification-select');
            setSelect(this, 'select.vehicle-select');
            setSelect(this, 'select.vehicle_serial-select');
            setSelect(this, 'select.repair-select');
            setSelect(this, 'select.request_destination-select');
            setSelect(this, 'select[name="consignor_id"]');
            // 指導記録-指導名
            if ($(this).hasClass('guidance-select')) {
                let checked = $('input[name="guidance_type"]:checked');
                if (checked.length > 0) {
                    let data = {
                        'section_id': $(this).val(),
                        'guidance_type': checked.val()
                    }
                    setSelect(this, 'select[name="guidance_kind_id"]', data);
                }
            }
        } else {
            clearSelect(this);
            setMultiSelect(this, 'select.instruction-multiple-select', {
                'section_id': $(this).val(),
                'authorizer_id': $(this).parents('form').find('select[name="authorizer_id"]').val(),
                'rollcall_type': (
                    ($(this).parents('form').find('input[name="rollcall_type"]:checked').length) ?
                        $(this).parents('form').find('input[name="rollcall_type"]:checked').val() :
                        $(this).parents('form').find('input[name="rollcall_type"]').val()
                ),
                'user_id': $(this).parents('form').find('select[name="user_id"]').val(),
                'default_instructions': $(this).parents('form').find('select[name="new_instructions[]"]').data('default-instructions'),
            }, function(companySelect, select, values) {
                $.each(values, function(i, value) {
                    $(select).multipleSelect('check', value);
                });
            });
        }
    });

    /*---- ユーザーのセレクト設定時 ----*/

    // セレクト設定時
    $(document).on('change', 'select.user-select', function () {
        commonUserSelectedEvent(this);
        setMultiSelected(this, 'select.group-select', {'user_id': $(this).val()})
    });

    /*---- ドライバーのセレクト設定時 ----*/

    // セレクト設定時
    $(document).on('change', 'select.driver-select', function () {
        commonUserSelectedEvent(this);
    });

    /*---- 車両のセレクト設定時 ----*/

     // セレクト設定時
    $(document).on('change', 'select.vehicle-select', function () {
        if ($(this).val() !== '') {
            setSelect(this, 'select[name="vehicle_equipment_id"]', {'vehicle_id': $(this).val()});
        } else {
            clearSelect(this, 'select[name="vehicle_equipment_id"]');
        }
    });

     /*---- 「廃車を含む」押下時 ----*/

     // 「廃車を含む」チェックボックス押下
     $(document).on('click', 'input[name=include_scrap_vehicle_flg]', function () {
         // 選択肢変更対象の要素
         let vehicleSelect = $(this).parents('form').find('select.vehicle-select');
         let companyVehicleSelect = $(this).parents('form').find('select.company-vehicle-select');
         if (vehicleSelect.length > 0) {
             // グループ会社セレクト要素
             let holdingSelect = $(this).parents('form').find('select.holding-select');
             // グループ会社が選択されていない初期表示では全選択肢を表示
             if (holdingSelect.find('option:selected').length > 0 && holdingSelect.find('option:selected').val() !== '') {
                 // 営業所セレクト要素
                 let sectionSelect = $(this).parents('form').find('select.section-select');
                 if (sectionSelect.length <= 0) {
                     return;
                 }
                 // グループ会社の設定はあるが、営業所が選択されていない場合は、選択肢を表示しない
                 if (sectionSelect.find('option:selected').length > 0 && sectionSelect.find('option:selected').val() !== '') {
                     setSelect(vehicleSelect, 'select.vehicle-select', { 'section_id': sectionSelect.val() });
                 } else {
                     clearSelect(vehicleSelect, 'select.vehicle-select');
                 }
             } else {
                 setSelect(vehicleSelect, 'select.vehicle-select');
             }
         } else if (companyVehicleSelect.length > 0) {
            // グループ会社セレクト要素
            let holdingSelect = $(this).parents('form').find('select.holding-select');
            // グループ会社が選択されていない初期表示では全選択肢を表示
            if (holdingSelect.find('option:selected').length > 0 && holdingSelect.find('option:selected').val() !== '') {
                // 会社セレクト要素
                let companySelect = $(this).parents('form').find('select.company-select');
                if (companySelect.length <= 0) {
                    return;
                }
                // グループ会社の設定はあるが、会社が選択されていない場合は、選択肢を表示しない
                if (companySelect.find('option:selected').length > 0 && companySelect.find('option:selected').val() !== '') {
                    setSelect(companySelect, 'select.company-vehicle-select', { 'company_id': companySelect.val() });
                } else {
                    clearSelect(companyVehicleSelect, 'select.company-vehicle-select');
                }
            } else {
                setSelect(companyVehicleSelect, 'select.company-vehicle-select');
            }
         }
     });

    /*---- アカウント未設定ユーザーのセレクト設定時 ----*/

    // セレクト設定時
    $(document).on('change', 'select.account-user-select', function () {
        setMultiSelected(this, 'select.group-select', {'user_id': $(this).val()})
    });

    /*---- 会社のセレクト設定時 ----*/

    // セレクト設定時
    let belongingProcessFlag = false;
    let instructionProcessFlag = false;
    $(document).on('change', '.rollcall-setting-rollcall-type, .rollcall-company-authorizer-select, #rollcall-setting-modal-user_id', function () {
        const companySelect = $(this).parents('form').find('.company-select')[0];
        const sectionSelect = $(this).parents('form').find('.section-select')[0];
        if (!$(this).hasClass('rollcall-company-authorizer-select')) {
            if (belongingProcessFlag) return;
            belongingProcessFlag = true;
            setMultiSelect(companySelect, 'select.belonging-multiple-select', {
                'rollcall_type': (
                    ($(this).parents('form').find('input[name="rollcall_type"]:checked').length) ? 
                        $(this).parents('form').find('input[name="rollcall_type"]:checked').val() :
                        $(this).parents('form').find('input[name="rollcall_type"]').val()
                ),
                'default_belongings': $(this).parents('form').find('select[name="belongings[]"]').data('default-belongings'),
            }, function(companySelect, select, values) {
                $.each(values, function(i, value) {
                    $(select).multipleSelect('check', value);
                });
            });
        }
        if (instructionProcessFlag) return;
        instructionProcessFlag = true;
        setMultiSelect(sectionSelect, 'select.instruction-multiple-select', {
            'section_id': $(this).val(),
            'authorizer_id': $(this).parents('form').find('select[name="authorizer_id"]').val(),
            'rollcall_type': (
                ($(this).parents('form').find('input[name="rollcall_type"]:checked').length) ?
                    $(this).parents('form').find('input[name="rollcall_type"]:checked').val() :
                    $(this).parents('form').find('input[name="rollcall_type"]').val()
            ),
            'user_id': $(this).parents('form').find('select[name="user_id"]').val(),
            'default_instructions': $(this).parents('form').find('select[name="new_instructions[]"]').data('default-instructions'),
        }, function(companySelect, select, values) {
            $.each(values, function(i, value) {
                $(select).multipleSelect('check', value);
            });
        });
        setTimeout(function() {
            belongingProcessFlag = false;
            instructionProcessFlag = false;
        }, 100);
    });

    // // セレクト２設定時
    // TODO: 重複してchangeイベントが発生しているためセレクト２のイベント変更は無視する
    // $(document).off('select2:selecting', '.select2');
    // $(document).on('select2:selecting', '.select2', function (e) {
    //     $(this).val(e.params.args.data.id);
    // });

     /**
      * select multiple の検索機能
      */
     // multiple検索エリアのイベント発火無効処理
     $(document).on('change', '.select-multiple--search-text input', function() {
         return false;
     });
     // multiple検索はkeyupで発火
     $(document).on('keyup', '.select-multiple--search-text input', function() {
         let select = $(this).parent('.select-multiple--search-text').siblings('select');
         let search = $(this).val();
         select.find('option').removeClass('d-none');
         if (search.length > 0) {
             select.find('option').filter(function(idx){
                 return $(this).text().indexOf(search) < 0;
             }).addClass('d-none');
         }
     });
     // option selected toggle制御
     $(document).on('mousedown', '.select-multiple--select option', function(e) {
         let self = $(this);
         let select = self.parent('select');
         if (e.shiftKey) {
             // (+shiftKey)複数指定の時は範囲指定でselectedする
             let options = select.find('option');
             let from, to;
             if (self.index() > select.find('option:selected').last().index()) {
                 from = select.find('option:selected').last().index();
                 to = self.index();
             } else {
                 from = self.index();
                 to = select.find('option:selected').last().index();
             }
             options.each(function(idx){
                 if (idx >= from && idx <= to) {
                     $(this).prop("selected", true);
                 }
             });
         } else {
             if (self.prop("selected")) {
                 self.prop("selected", false);
             } else {
                 self.prop("selected", true);
             }
         }
         select.trigger('change');
     });
     // multiple 選択解除
     $(document).on('click', '.select-multiple--label .select-reset', function() {
         let select = $(this).parent('.select-multiple--label').siblings('select');
         select.find('option').prop('selected', false);
         select.trigger('change');
     });

     // select-multiple--fold-select-panel focus, blurの表示・非表示制御
    $(document).on('focus', 'div.select-multiple--fold-select-panel-container input', function () {
        $(this).parents('div.select-multiple--fold-select-panel-container').find('select.select-multiple--fold-select-panel').show();
    });
    $(document).on('blur', 'div.select-multiple--fold-select-panel-container input', function (e) {
        const parent = $(this).parents('div.select-multiple--fold-select-panel-container');
        if(false == $(e.relatedTarget).hasClass('select-multiple--fold-select-panel')) {
            parent.find('select.select-multiple--fold-select-panel').hide();
        }
        let showText = '';
        parent.find('option:selected').each(function(i, elem) {
            if(showText.length > 0)
                showText += ",";
            showText += $(elem).text();
        })
        $(this).val(showText);
    });

});
