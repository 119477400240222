
import { RemoteRollcallMeeting } from '../modules/remote_rollcall_meeting';
import { FaceAuthentication, FaceAuthRegister } from '../modules/face_authentication';
import { RemoteRollcallViewer } from '../modules/remote_rollcall_viewer';

const faceAuth = new FaceAuthentication('remote_rollcall-recognition-modal-video');
const faceAuthRegister = new FaceAuthRegister('remote_rollcall-facial-modal-video');
const viewer = new RemoteRollcallViewer(5000, function() {
    return (!meeting.isResponding && !faceAuthRegister.isRegisting && !faceAuth.isAuthenticating);
});
const meeting = new RemoteRollcallMeeting(function() {
    viewer.stop();
}, function(results) {
    setMultiSelect($('#remote_rollcall-join-modal-company_id'), 'select.belonging-multiple-select', {
        'company_id': $('#remote_rollcall-join-modal-company_id').val(),
        'rollcall_type': $('#remote_rollcall-join-modal input[name="rollcall_type"]:checked').val()
    }, function(companySelect, select, values) {
        $.each(values, function(i, value) {
            $(select).multipleSelect('check', value);
        });
    });
    setMultiSelect($('#remote_rollcall-join-modal-section_id'), 'select.instruction-multiple-select', {
        'section_id': $('#remote_rollcall-join-modal-section_id').val(),
        'rollcall_type': $('#remote_rollcall-join-modal input[name="rollcall_type"]:checked').val()
    }, function(companySelect, select, values) {
        $.each(values, function(i, value) {
            $(select).multipleSelect('check', value);
        });
    });
}, function() {
    const pause = $('[data-remote-rollcall-pause-action]').data('remote-rollcall-pause-action');
    const meetingId = $('[data-remote-rollcall-pause-action]').data('meeting_id');
    if (!$('#remote_rollcall-join-modal').data('is-superuser')) {
        _ajax.background(pause, {'meeting_id': meetingId});
    }
});

/**
 * 全ページ共通のスクリプト
 */
$(document).on('bss:onload', function() {
    

    /*---- 顔登録処理 ----*/

    $(document).on('click', '[data-target="#remote_rollcall-facial-modal"]', function() {

        faceAuthRegister.selectableCamera().start(function() {
            $('[data-facial-save-modal=remote_rollcall-facial-modal]').attr('disabled', false);
        });

        // カメラプルダウン変更時イベントを追加
        $(document).off('change', '#remote_rollcall-facial-modal-video-inputs');
        $(document).on('change', '#remote_rollcall-facial-modal-video-inputs', function() {
            faceAuthRegister.changeCamera($(this).val());
        });

    });
    $(document).on('hidden.bs.modal', '#remote_rollcall-facial-modal', function() {
        //superuser対応
        $('#remote_rollcall-facial-modal').removeData('is-remote-rollcall-start');
        $('#remote_rollcall-facial-modal').css('visibility', 'visible');
        
        faceAuthRegister.cancel();
    });
    $(document).on('click', '[data-facial-save-modal=remote_rollcall-facial-modal]', function() {
        const capture = faceAuthRegister.capture();
        $('#remote_rollcall-facial-modal-image').val(capture);
        _modal.submit(this, 'facial-save-modal', function() {
            faceAuthRegister.complete();
        }, function() {
            faceAuthRegister.error();
        });
    });

    /*---- 顔認証処理 ----*/

    $(document).on('hidden.bs.modal', '#remote_rollcall-recognition-modal', function() {
        const cancel = $(this).data('cancel-action');
        const meetingId = $(this).data('meeting_id');
        $('#remote_rollcall-recognition-modal').removeData('is-remote-rollcall-start');
        $('#remote_rollcall-recognition-modal').css('visibility', 'visible');
        if (!$('#remote_rollcall-recognition-modal').data('is-superuser')) {
            _ajax.background(cancel, {'meeting_id': meetingId});
        }
        faceAuth.cancel();
    });
    $(document).on('shown.bs.modal', '#remote_rollcall-recognition-modal', function() {

        const list = $(this).data('list-action');
        const action = $(this).data('action');
        const status = $(this).data('status-action');
        const isStandby = $(this).data('is_standby');
        const meetingId = $(this).data('meeting_id');

        if (!$('#remote_rollcall-recognition-modal').data('is-superuser')) {
            _ajax.background(status, {'meeting_id': meetingId});
        }

        // リロード・クローズ前にモーダルを閉じる
        $(window).on('beforeunload', function() {
            _modal.hide('#remote_rollcall-recognition-modal');
        });

        faceAuth.selectableCamera().start(function(capture) {
            let datas = [];
            datas.push({'name': 'image', 'value': capture});
            if (isStandby) {
                datas.push({'name': 'is_standby', 'value': true});
            }
            if (($('#remote_rollcall-recognition-modal').data('is-superuser') || $('#remote_rollcall-recognition-modal').data('is-anest-support-user'))
                && $('#remote_rollcall-recognition-modal').data('is-remote-rollcall-start')) {
                datas.push({'name': 'is_skip_face_recognition', 'value': true});
            }
            _ajax.background(action, datas, function (results) {
                if (results.status) {
                    // 完了メッセージをセットする
                    _complete.set(results);
                    // モーダルを非表示
                    $('#remote_rollcall-recognition-modal').modal('hide');
                    if (meetingId != null) {
                        // カメラを終了してから遠隔点呼を開始
                        _camera.end('remote_rollcall-recognition-modal-video');
                        meeting.join(meetingId);
                    } else {
                        location.href = list;
                    }
                }
                faceAuth.complete();
            }, function(xhr, status, errorThrown) {
                faceAuth.error();
                _error.omitted(xhr, status, errorThrown);
            });
        });

        // カメラプルダウン変更時イベントを追加
        $(document).off('change', '#remote_rollcall-recognition-modal-video-inputs');
        $(document).on('change', '#remote_rollcall-recognition-modal-video-inputs', function() {
            faceAuth.changeCamera($(this).val());
        });

    });

    /*---- 遠隔点呼待機フラグ変更、メニューアイコンクリック時（顔未認証） ----*/

    $(document).on('click', '#remote-rollcall-recognition', function() {
        $(this).prop('checked', false);
        $('#remote_rollcall-recognition-modal').data('is_standby', true);
        //superuser対応
        if($('#remote_rollcall-recognition-modal').data('is-superuser') || $('#remote_rollcall-recognition-modal').data('is-anest-support-user')) {
            $('#remote_rollcall-recognition-modal').data('is-remote-rollcall-start', true);
            $('#remote_rollcall-recognition-modal').css('visibility', 'hidden');
        }
        _modal.show('#remote_rollcall-recognition-modal');
    });

    $(document).on('click', '[data-remote-rollcall-recognition=true]', function() {
        $('#remote_rollcall-recognition-modal').removeData('is_standby');
        //superuser対応
        if($('#remote_rollcall-recognition-modal').data('is-superuser') || $('#remote_rollcall-recognition-modal').data('is-anest-support-user')) {
            $('#remote_rollcall-recognition-modal').data('is-remote-rollcall-start', true);
            $('#remote_rollcall-recognition-modal').css('visibility', 'hidden');
        }
        _modal.show('#remote_rollcall-recognition-modal');
    });

    /*---- IT/遠隔点呼待機フラグ変更時 ----*/

    $(document).on('change', '#remote-rollcall-standby, #it-rollcall-standby', function() {
        const isEnable = $(this).prop('checked');
        const isIt = ($('#remote-rollcall-stanby-actions').data('it_type') == $(this).data('type'));
        var action = $('#remote-rollcall-stanby-actions').data('remote-standby-action');
        var list = $('#remote-rollcall-stanby-actions').data('remote-list-action');
        if (isIt) {
            action = $('#remote-rollcall-stanby-actions').data('it-standby-action');
            list = $('#remote-rollcall-stanby-actions').data('it-list-action');
            if ($('#remote-rollcall-standby').prop('checked')) {
                list = $('#remote-rollcall-stanby-actions').data('remote-list-action');
            }
        }
        const datas = {
            'is_enable': isEnable,
        };
        _ajax.post(action, datas, function (results) {
            if (isEnable) {
                location.href = list;
            } else {
                if ($('#remote-rollcall-standby').prop('checked') || $('#it-rollcall-standby').prop('checked')) {
                    location.reload();
                } else {
                    location.href = $('#remote-rollcall-stanby-actions').data('dashboard-action');
                }
            }
        }, function(xhr, status, errorThrown){
            _error.omitted(xhr, status, errorThrown);
        });
    });

    /*---- 点呼執行者選択時 ----*/

    // モーダル非表示
    $(document).on('hidden.bs.modal', '#it_rollcall-authorizer-modal', function() {
        const cancel = $(this).data('cancel-action');
        const meetingId = $(this).data('meeting_id');
        if (!$('#it_rollcall-authorizer-modal').data('is-superuser')) {
            _ajax.background(cancel, {'meeting_id': meetingId});
        }
        faceAuth.cancel();
    });

    // モーダル表示
    $(document).on('shown.bs.modal', '#it_rollcall-authorizer-modal', function() {

        const status = $(this).data('status-action');
        const meetingId = $(this).data('meeting_id');

        if (!$('#it_rollcall-authorizer-modal').data('is-superuser')) {
            _ajax.background(status, {'meeting_id': meetingId});
        }

        // リロード・クローズ前にモーダルを閉じる
        $(window).on('beforeunload', function() {
            _modal.hide('#it_rollcall-authorizer-modal');
        });
    });

    // モーダルサブミット
    $(document).on('click', '#it_rollcall-authorizer-modal-submit', function() {
        $('#it_rollcall-authorizer-modal').modal('hide');
        meeting.join($('#it_rollcall-authorizer-modal-meeting_id').val(), $('#it_rollcall-authorizer-modal-authorizer_id').val(), $('#remote-rollcall-stanby-actions').data('it-join-action'));
    });

    /*---- 点呼再接続確認時 ----*/

    $(document).on('hidden.bs.modal', '#remote_rollcall-join-confirm-modal', function() {
        meeting.isResponding = false;
    });

    /*---- drawer部制御(詳細表示エリア) ----*/

    RemoteRollcallViewer.initializeDetailArea();

    /*---- IT点呼応答時の点呼執行者選択モーダル内の絞り込みチェックボックス押下時 ----*/

    $(document).on('click', '#it_rollcall-authorizer-modal-authorizer_filtering_flg', function() {
        const action = $(this).data('user_setting_save_action');
        const key = $(this).data('user_setting_key');
        const value = $(this).prop('checked') ? 1 : 0;
        const params = {'setting_key': key, 'setting_value' : value};
        // 表示保持用API(user_setting)
        _ajax.post(action, params, function (results) {
        }, function (xhr, status, errorThrown) {
            _error.omitted(xhr, status, errorThrown);
        });
        // チェックの有無に合わせて執行者一覧取得
        if ($(this).prop('checked')) {
            const sectionId = $(this).data('user_section_id');
            setSelect($(this), 'select.rollcall-authorizer-select', {'type': 'authorizer', 'section_id': sectionId});
        } else {
            const holdingId = $(this).data('user_holding_id');
            setSelect($(this), 'select.rollcall-authorizer-select', {'type': 'authorizer', 'holding_id': holdingId});
        }
    });

});
/**
 * 遠隔点呼ページ用のスクリプト
 */
if (is_route('remote_rollcall.index') || is_route('it_rollcall.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        remote_rollcall_event_set();
        remote_rollcall_detail_event_set();
    });
}
/**
 * ページ読み込み時のイベント設定
 */
window.remote_rollcall_event_set = function() {

    /*---- 応答押下時 ----*/

    // 応答押下時（遠隔点呼）
    $(document).on('click', '[data-join-modal]', function () {
        if ($(this).data('is_superuser') || $(this).data('is_anest-support-user')) {
            remote_rollcall_join($(this).data('meeting_id'));
            return;
        }
        $('#remote_rollcall-recognition-modal').data('meeting_id', $(this).data('meeting_id'));
        $('#remote_rollcall-recognition-modal').modal('show');
    });

    // 応答押下時（IT点呼）
    $(document).on('click', '[data-join-btn]', function() {
        RemoteRollcallViewer.authorizerSelect($(this).data('meeting_id'));
    });

    /*---- 設定の保存時 ----*/

    // 設定実行時
    $(document).on('click', '[data-save-modal]', function () {
        _modal.submit(this, 'save-modal', function(results) {
            let keys = Object.keys(results);
            if (keys.find(key => key == 'holding')) {
                $('[data-replace-name-holding-id=' + results.holding.id + ']').text(results.holding.name);
            }
            if (keys.find(key => key == 'remote_rollcall')) {
                $('[data-replace-name-remote_rollcall-id=' + results.remote_rollcall.id + ']').text(results.remote_rollcall.name);
            }
        });
    });

    /*---- 削除確認の表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-confirm-modal]', function () {
        // 設定モーダルを表示
        _modal.create(this, 'confirm-modal', ['id']);
    });

    /*---- 削除時 ----*/

    // 削除実行時
    $(document).on('click', '[data-delete-modal]', function () {
        _modal.submit(this, 'delete-modal', function(results) {
            // ツールバーの更新が必要なのでリロードする
            location.reload();
        });
    });

}
/*+
 * 詳細モーダル表示時のイベント設定
 */
window.remote_rollcall_detail_event_set = function () {

    /*---- 点呼タイプ変更時のフォーム表示 ----*/

    // トグルボタン押下時
    $(document).on('change', '.rollcall-setting-rollcall-type', function () {
        remote_rollcall_setting_set_by_rollcall_type(this);
    });

    /*---- 詳細表示時 ----*/

    // 詳細表示時
    $(document).on('click', '[data-detail-modal]', function (e) {
        _modal.create(this, 'detail-modal', ['id', 'date', 'user_id', 'vehicle_id']);
    });
}
window.remote_rollcall_setting_set_by_rollcall_type = function (selector) {
    let type = $(selector).val();
    let formId = create_id($(selector).data('form-id'));
    // 業務後点呼の場合
    if (type == '3') {
        $(formId).find('.only-type-3').each(function() {
            $(this).removeClass('display-none');
        });
        $(formId).find('.only-type').each(function() {
            $(this).addClass('display-none');
        });
    } else {
        $(formId).find('.only-type-3').each(function() {
            $(this).addClass('display-none');
        });
        $(formId).find('.only-type').each(function() {
            $(this).removeClass('display-none');
        });
    }
}
/**
 * API側の遠隔点呼アクセス時
 */
if (is_route('api.cm.remote_rollcall.join') || is_route('api.local.remote_rollcall.join')) {
    meeting.start('remote_rollcall-api-join', false);
}