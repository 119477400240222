/**
 * ダッシュボード内情報管理用のスクリプト
 */
if (is_route('dashboard_data.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        dashboard_data_event_set();
        // dashboard.index.jsから呼び出し
        dashboard_setting_event_set();
    });
}

/**
 * ページ読み込み時のイベント設定
 */
window.dashboard_data_event_set = function() {

    // タブ切り替え時
    $(document).on('click', '[data-page-type]', function() {
        $('#search-form-type').val($(this).data('page-type')).trigger('change');
    });

    /*----  スケジュール設定表示時 ----*/

    // スケジュール設定表示時
    $(document).on('click', '[data-schedule-setting-modal]', function () {
        _modal.create(this, 'schedule-setting-modal', ['id', 'type', 'task-id'], true);
    });

    /*----  スケジュール設定保存時 ----*/

    //  スケジュール設定保存時
    $(document).on('click', '[data-schedule-modal]', function () {
        _modal.submit(this, 'schedule-modal');
    });

    /*----  スケジュール削除確認時 ----*/

    // スケジュールの削除確認モーダル
    $(document).on('click', '[data-schedule-confirm-modal]', function () {
        _modal.create(this, 'schedule-confirm-modal', ['id']);
    });

    /*----  スケジュール削除時 ----*/

    // スケジュール削除実行時
    $(document).on('click', '[data-schedule-delete-modal]', function () {
        _modal.submit(this, 'schedule-delete-modal', function(results) {
            _modal.hide('#schedule-setting-' + results.schedule.id);
        });
    });

    /*---- お知らせ完了確認時 ----*/

    // お知らせ完了確認モーダル
    $(document).on('click', '[data-information-complete-confirm-modal]', function (e) {
        _modal.create(this, 'information-complete-confirm-modal', ['id', 'class_name', 'key']);
        // 親要素へのクリックイベント伝搬を回避する
        e.preventDefault();
        e.stopPropagation();
    });

    /*---- お知らせ完了実行時 ----*/

    $(document).on('click', '[data-information-complete-modal]', function () {
        _modal.submit(this, 'information-complete-modal');
    });

    /*---- 開いた通知の状態を保持 ----*/

    // 開いた通知の状態を保持
    $(document).on('click', '.information-button', function() {
        let type = $(this).data('type');
        _request.set_parameters([{'name': 'type', 'value': type}]);
    });

    /*---- 通知からの設定表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-setting-modal]', function(e) {
        if ($(e.target).hasClass('file-download')) {
            return;
        }
        // 設定モーダルを表示
        _modal.create(this, 'setting-modal', ['id', 'type', 'edit_type', 'date', 'user_id', 'vehicle_id', 'inspection_id', 'is_workflow_information'], false, function() {
            if ($('.holiday-status-flag:checked').length) {
                attendance_absence_flag_event($('.holiday-status-flag:checked'));
            }
        });
    });

    /*---- 通知からの画面遷移 ----*/

    // ページ移動
    $(document).on('click', '[data-location]', function() {
        location.href = $(this).data('location');
    });

    /*---- 通知からの設定保存時 ----*/

    // 設定保存時
    $(document).on('click', '[data-save-modal]', function () {
        const modalId = $(this).data('save-modal');
        const formId = create_id($(this).data('save-modal') + '-form');
        // 日時が送信されないと休暇系の算出が行えないためdisabledを解除する
        $.each($(formId).find('[name=work_started_at]:disabled,[name=work_ended_at]:disabled,[name=rest_started_at]:disabled,[name=rest_ended_at]:disabled'), function() {
            $(this).prop('disabled', false);
        });
        _modal.submit(this, 'save-modal', function(results) {
            if (modalId.indexOf('rollcall') >= 0) {
                $('[data-detail-modal=rollcall-detail-modal][data-date=' + moment(results.rollcall.rollcalled_at).format("YYYY-MM-DD") + ']').trigger('click');
            }
        }, function() {
            // ステップエラーを表示
            user_set_steper_error();
            vehicle_set_steper_error();
        });
    });

    /*---- 通知からの点呼設定画面の表示（点呼専用） ----*/

    // 設定表示時（点呼専用）
    $(document).on('click', '[data-detail-modal]', function() {
        // 設定モーダルを表示
        _modal.create(this, 'detail-modal', ['id', 'type', 'date', 'user_id']);
    });

    /*---- 通知からの削除確認時 ----*/

    // 削除確認時
    $(document).on('click', '[data-confirm-modal]', function() {
        // 設定モーダルを表示
        _modal.create(this, 'confirm-modal', ['id']);
    });

    /*---- 通知からの削除時 ----*/

    // 削除時
    $(document).on('click', '[data-delete-modal]', function () {
        _modal.submit(this, 'delete-modal', function(results) {
            $('.modal').each(function() {
                _modal.hide($(this));
            });
        });
    });

    /*---- タスク設定表示時 ----*/

    // タスク設定表示時
    $(document).on('click', '[data-task-setting-modal]', function (e) {
        e.preventDefault();
        e.stopPropagation();
        _modal.create(this, 'task-setting-modal', ['key', 'id', 'model_id', 'model_class']);
    });

    /*---- タスクステータス変更 ----*/

    $(document).on('change', 'select[name="status"]', function() {
        let required = $('select[name="responder_id"]').parent().find('.badge-required');
        if($(this).val() == '4'){
            $(required).addClass('display-none');
        }else{
            $(required).removeClass('display-none');
        }
    });

    /*---- タスク保存時 ----*/

    // タスク保存時
    $(document).on('click', '[data-task-modal]', function () {
        _modal.submit(this, 'task-modal');
    });

    /*---- タスク削除確認表示時 ----*/

    // 削除確認表示時
    $(document).on('click', '[data-task-confirm-modal]', function () {
        // 詳細モーダルを表示
        _modal.create(this, 'task-confirm-modal', ['id']);
    });

    /*---- タスク削除時 ----*/

    // タスク削除時
    $(document).on('click', '[data-task-delete-modal]', function () {
        _modal.submit(this, 'task-delete-modal', function(results) {
            _modal.hide('#task-setting-' + results.task.id);
        });
    });

    // スケジュール追加済タスクの「スケジュール追加」ボタンをクリック不可にする
    // （ツールチップと併用するためcssではなくjsで制御）
    $('.task .btn-task-close').on('click', function(event) {
        event.stopPropagation();
        return false;
    });
    
}
