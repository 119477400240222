/**
 * 診断ページ用のスクリプト
 */
if (is_route('blood_pressure.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        blood_pressure_event_set();
    });
}
/**
 * ページ読み込み時のイベント設定
 */
window.blood_pressure_event_set = function() {

    /*---- 設定表示時 ----*/

    // 詳細表示
    $(document).on('click', '[data-detail-modal]', function () {
        _modal.create(this, 'detail-modal', ['user_id', 'measurement_from', 'measurement_to'], true);
    });

    /*---- 設定表示時 ----*/

    // 詳細表示
    $(document).on('click', '[data-history-modal]', function () {
        _modal.create(this, 'history-modal', ['user_id', 'measurement_datetime', 'rollcall_type'], true);
    });

    /*---- チャート表示処理 ----*/

    // チャート表示
    $(document).on('click', '[data-chart-modal]', function () {
        // モーダルのID取得とキャンバスIDを生成
        var modalId = $(this).data('chart-modal');
        var canvasId = $(this).data('canvas-id');
        var action = $(this).data('action');
        // 検索フォームを配列化
        let data = $('#search-form').serializeArray();
        data.push({'name' : 'user_id', 'value' : $(this).data('user_id')});
        data.push({'name' : 'measurement_from', 'value' : $(this).data('measurement_from')});
        data.push({'name' : 'measurement_to', 'value' : $(this).data('measurement_to')});
        // チャート表示
        blood_pressure_chart_event_set(action, modalId, canvasId, data, function(results) {
            $('input[name="chart_type"]').change(function(){
                draw_chart(canvasId, results, $(this).val());
            })
        });
    });
}

/**
 * チャート表示を行う
 * @param {*} action
 * @param {*} modalId
 * @param {*} canvasId
 * @param {*} data
 * @param {*} callback
 */
window.blood_pressure_chart_event_set = function(action, modalId, canvasId, data, callback = null) {
    // 送信
    _ajax.post(action, data, function (results) {
        $(modalId + ' #modal-title').text(results.title);
        $(modalId + ' #period').text(results.period);
        $(modalId + ' #user_code').text(results.user_code);
        $(modalId + ' #user_name').text(results.user_name);

        draw_chart(canvasId, results, $('input[name="chart_type"]:checked').val());
        // モーダルopen
        _modal.show(modalId);
        // callback
        if (callback != null) {
            callback(results);
        }
    }, function(xhr, status, errorThrown){
        _error.omitted(xhr, status, errorThrown);
    });
}

/**
 * チャート描画
 * @param {*} canvasId
 * @param {*} results
 * @param {*} type
 */
window.draw_chart = function(canvasId, results, type = '') {
    // 目盛フォントサイズ
    var ticksFontSize = 14;
    var dataSets = [];
    var annotations = [];

    let beforeData = [
        {
            fill: false,
            lineTension: 0.01,
            spanGaps: true,
            label: results.datasets.before.max_blood_pressures.label,
            data: results.datasets.before.max_blood_pressures.data,
            borderColor: "rgb(194,21,21,0.7)",
            backgroundColor: "rgb(141,15,15,0.7)",
            yAxisID: "blood_pressure-y-axis",
            tooltips: results.datasets.before.max_blood_pressures.data,
        },
        {
            fill: false,
            lineTension: 0.01,
            spanGaps: true,
            label: results.datasets.before.min_blood_pressures.label,
            data: results.datasets.before.min_blood_pressures.data,
            borderColor: "rgba(26,74,196,0.7)",
            backgroundColor: "rgba(17,45,117,0.7)",
            yAxisID: "blood_pressure-y-axis",
            tooltips: results.datasets.before.min_blood_pressures.data,
        },
        {
            fill: false,
            lineTension: 0.01,
            spanGaps: true,
            label: results.datasets.before.pulses.label,
            data: results.datasets.before.pulses.data,
            borderColor: "rgba(28,175,35,0.5)",
            backgroundColor: "rgba(18,114,23,0.5)",
            yAxisID: "pulse-y-axis",
            tooltips: results.datasets.before.pulses.data,
        },
    ];
    let afterData = [
        {
            fill: false,
            lineTension: 0.01,
            spanGaps: true,
            label: results.datasets.after.max_blood_pressures.label,
            data: results.datasets.after.max_blood_pressures.data,
            borderColor: "rgb(194,21,21,0.7)",
            backgroundColor: "rgb(141,15,15,0.7)",
            borderDash: [10,5],
            yAxisID: "blood_pressure-y-axis",
            tooltips: results.datasets.after.max_blood_pressures.data,
        },
        {
            fill: false,
            lineTension: 0.01,
            spanGaps: true,
            label: results.datasets.after.min_blood_pressures.label,
            data: results.datasets.after.min_blood_pressures.data,
            borderColor: "rgba(26,74,196,0.7)",
            backgroundColor: "rgba(17,45,117,0.7)",
            borderDash: [10,5],
            yAxisID: "blood_pressure-y-axis",
            tooltips: results.datasets.after.min_blood_pressures.data,
        },
        {
            fill: false,
            lineTension: 0.01,
            spanGaps: true,
            label: results.datasets.after.pulses.label,
            data: results.datasets.after.pulses.data,
            borderColor: "rgba(28,175,35,0.5)",
            backgroundColor: "rgba(18,114,23,0.5)",
            borderDash: [10,5],
            yAxisID: "pulse-y-axis",
            tooltips: results.datasets.after.pulses.data,
        },
    ];
    var beforeAnnotations = [
        {
            type: 'line',
            id: 'beforeMaxLine',
            mode: 'horizontal',
            scaleID: 'blood_pressure-y-axis',
            value: results.average.before.max.data,
            borderWidth: 4,
            borderColor: "rgba(229,51,93,0.6)",
            label: {
                position: 'left',
                yAdjust: 14,
                content: results.average.before.max.label,
                enabled: true,
                fontSize: ticksFontSize,
                backgroundColor: "rgba(210,27,71,0.6)",
            }
        },
        {
            type: 'line',
            id: 'beforeMinLine',
            mode: 'horizontal',
            scaleID: 'blood_pressure-y-axis',
            value: results.average.before.min.data,
            borderWidth: 4,
            borderColor: "rgba(74,145,224,0.6)",
            label: {
                position: 'left',
                yAdjust: -14,
                content: results.average.before.min.label,
                enabled: true,
                fontSize: ticksFontSize,
                backgroundColor: "rgba(38,110,190,0.6)",
            }
        },
    ];
    var afterAnnotations = [
        {
            type: 'line',
            id: 'AfterMaxLine',
            mode: 'horizontal',
            scaleID: 'blood_pressure-y-axis',
            value: results.average.after.max.data,
            borderWidth: 4,
            borderColor: "rgba(210,27,71,0.6)",
            borderDash: [10,5],
            label: {
                position: 'right',
                yAdjust: 14,
                content: results.average.after.max.label,
                enabled: true,
                fontSize: ticksFontSize,
                backgroundColor: "rgba(210,27,71,0.6)",
            }
        },
        {
            type: 'line',
            id: 'AfterMinLine',
            mode: 'horizontal',
            scaleID: 'blood_pressure-y-axis',
            value: results.average.after.min.data,
            borderWidth: 4,
            borderColor: "rgba(74,145,224,0.6)",
            borderDash: [10,5],
            label: {
                position: 'right',
                yAdjust: -14,
                content: results.average.after.min.label,
                enabled: true,
                fontSize: ticksFontSize,
                backgroundColor: "rgba(38,110,190,0.6)",
            }
        },
    ];

    // タイプ毎データ編集
    if (type === '1') {
        dataSets = beforeData;
        annotations = beforeAnnotations
    } else if (type === '3') {
        dataSets = afterData;
        annotations = afterAnnotations
    } else {
        dataSets = beforeData.concat(afterData);
        annotations = beforeAnnotations.concat(afterAnnotations);
    }
    // チャートクリア
    if (typeof myChart !== 'undefined' && myChart) {
        myChart.destroy();
    }

    window.myChart = chart(canvasId, 'line', {
        labels: results.labels,
        datasets: dataSets,
    }, {
        legend: {
            position: "bottom",
            onClick: function () { return false },
            labels: {
                fontSize: ticksFontSize
            }
        },
        responsive: true,
        scales: {
            yAxes: [{
                id: "blood_pressure-y-axis",
                type: "linear",
                position: "left",
                scaleLabel: {
                    display: true,
                    labelString: results.y_left_label,
                },
                ticks: {
                    autoSkip: false,
                    max: results.ticks.blood_pressure[results.ticks.blood_pressure.length - 1],
                    min: results.ticks.blood_pressure[0],
                    fontSize: ticksFontSize
                },
            },
                {
                    id: "pulse-y-axis",
                    type: "linear",
                    position: "right",
                    scaleLabel: {
                        display: true,
                        labelString: results.y_right_label,
                    },
                    ticks: {
                        autoSkip: false,
                        max: results.ticks.pulse[results.ticks.pulse.length - 1],
                        min: results.ticks.pulse[0],
                        fontSize: ticksFontSize
                    },
                    gridLines: {
                        drawOnChartArea: false,
                        // borderDash: [20,30],
                        // color: "rgba(114,61,18,0.2)"
                    },
                }],
            xAxes: [{
                ticks: {
                    fontSize: ticksFontSize
                },
            }],
        },
        //基準線を引く
        annotation: {
            annotations: annotations
        },
    });
}
