/**
 * ダッシュボードページ用のスクリプト
 */
if (is_route('dashboard.index')) {
    // コンテンツ読み込み後
    $(document).on('bss:onload', function() {
        dashboard_event_set();
        dashboard_load_contents();
        dashboard_setting_event_set();
    });
    // コンテンツの再読み込み後
    $(document).on('bss:reload', function() {
        dashboard_load_contents();
    });
}
/**
 * ページ読み込み時のイベント設定
 */
window.dashboard_event_set = function () {

    /*---- タスク追加時のバブリングを禁止 ----*/

    // ボタンクリック時のバブリングを禁止
    $(document).on('click', '.information-list .list-group-item .btn[data-toggle=modal]', function (e) {
        e.preventDefault();
        e.stopPropagation();
    });

    /*---- 開いた通知の状態を保持 ----*/

    // 開いた通知の状態を保持
    $(document).on('click', '.information-button', function() {
        let type = $(this).data('type');
        _request.set_parameters([{'name': 'type', 'value': type}]);
    });

    /*---- 通知からの設定表示時 ----*/

    // 設定表示時
    $(document).on('click', '[data-setting-modal]', function(e) {
        if ($(e.target).hasClass('file-download')) {
            return;
        }
        // 設定モーダルを表示
        _modal.create(this, 'setting-modal', ['id', 'holding_id', 'company_id', 'section_id', 'type', 'edit_type', 'date', 'user_id', 'vehicle_id', 'inspection_id', 'is_workflow_information', 'relation_id'], false, function() {
            if ($('.holiday-status-flag:checked').length) {
                attendance_absence_flag_event($('.holiday-status-flag:checked'));
            }
        });
    });

    /*---- 通知からの画面遷移 ----*/

    // ページ移動
    $(document).on('click', '[data-location]', function() {
        location.href = $(this).data('location');
    });

    /*---- 通知からの設定保存時 ----*/

    // 設定保存時
    $(document).on('click', '[data-save-modal]', function () {
        const modalId = $(this).data('save-modal');
        const formId = create_id($(this).data('save-modal') + '-form');
        // 日時が送信されないと休暇系の算出が行えないためdisabledを解除する
        $.each($(formId).find('[name=work_started_at]:disabled,[name=work_ended_at]:disabled,[name=rest_started_at]:disabled,[name=rest_ended_at]:disabled'), function() {
            $(this).prop('disabled', false);
        });

        // 点呼簿 処理
        let rollcallExpectation = $('input[name="is_expectation"]');
        let rollcallManual = $('input[name="is_manual"]');
        if (modalId.indexOf('rollcall') >= 0) {
            // 予定点呼（手動登録）新規追加 or 予定点呼（自動登録）更新 の場合は送信時にdisabledを解除
            if (rollcallManual.length > 0 || (rollcallManual.length === 0 && rollcallExpectation.length > 0 && rollcallExpectation.val() === '1')) {
                $(formId).find('input:not([type=hidden]),select,textarea').prop('disabled', false);
            }
        }

        _modal.submit(this, 'save-modal', function(results) {
            if (modalId.indexOf('rollcall') >= 0) {
                $('[data-detail-modal=rollcall-detail-modal][data-date=' + moment(results.rollcall.rollcalled_at).format("YYYY-MM-DD") + ']').trigger('click');
            }
        }, function(xhr) {
            // 社員台帳 BadRequest時 ファイル系エラー表示
            if (xhr.status === 400 && modalId.indexOf('user') !== -1) {
                let json = xhr.responseJSON;
                let errors = json.errors;
                user_set_file_error(modalId, errors);
            }

            // 点呼簿 処理
            if (modalId.indexOf('rollcall') >= 0) {
                // 送信結果がエラーだった場合、送信時に解除したdisabledを再設定
                if (rollcallManual.length > 0 || rollcallManual.length === 0 && rollcallExpectation.length > 0 && rollcallExpectation.val() === '1') {
                    rollcall_setting_set_by_rollcall_type($('.rollcall-setting-rollcall-type:checked'));
                }
            }

            // ステップエラーを表示
            user_set_steper_error();
            vehicle_set_steper_error();
        });
    });

    /*---- 通知からの点呼設定画面の表示（点呼専用） ----*/

    // 設定表示時（点呼専用）
    $(document).on('click', '[data-detail-modal]', function() {
        // 設定モーダルを表示
        _modal.create(this, 'detail-modal', ['id', 'type', 'date', 'user_id']);
    });

    /*---- 通知からの削除確認時 ----*/

    // 削除確認時
    $(document).on('click', '[data-confirm-modal]', function() {
        // 設定モーダルを表示
        _modal.create(this, 'confirm-modal', ['id']);
    });

    /*---- 通知からの削除時 ----*/

    // 削除時
    $(document).on('click', '[data-delete-modal]', function () {
        _modal.submit(this, 'delete-modal', function(results) {
            $('.modal').each(function() {
                _modal.hide($(this));
            });
        });
    });

    /*---- 通知からタスクのドロップ時 ----*/

    // ドロップ時
    $(document).on('drop', '.task-list', function() {
        var dropId = create_id(event.dataTransfer.getData('id'));
        // var modalId = $(dropId).data('information-to-task-modal');
        // _modal.show(modalId);
        let params = {
            'key' : $(dropId).data('key'),
            'id' : $(dropId).data('task_id'),
            'model_id' : $(dropId).data('model_id'),
            'model_class' : $(dropId).data('model_class'),
        };
        _modal.create_with_data($(dropId), 'information-to-task-modal', params, false, null, 'task_action');
    });

    /*---- タスク設定表示時 ----*/

    // タスク設定表示時
    $(document).on('click', '[data-task-setting-modal]', function (e) {
        e.preventDefault();
        e.stopPropagation();
        _modal.create(this, 'task-setting-modal', ['key', 'id', 'model_id', 'model_class']);
    });

    /*---- タスクステータス変更 ----*/

    $(document).on('change', 'select[name="status"]', function() {
        let required = $('select[name="responder_id"]').parent().find('.badge-required');
        if($(this).val() == '4'){
            $(required).addClass('display-none');
        }else{
            $(required).removeClass('display-none');
        }
    });

    /*---- タスク保存時 ----*/

    // タスク保存時
    $(document).on('click', '[data-task-modal]', function () {
        _modal.submit(this, 'task-modal');
    });

    /*---- タスク削除確認表示時 ----*/

    // 削除確認表示時
    $(document).on('click', '[data-task-confirm-modal]', function () {
        // 詳細モーダルを表示
        _modal.create(this, 'task-confirm-modal', ['id']);
    });

    /*---- タスク削除時 ----*/

    // タスク削除時
    $(document).on('click', '[data-task-delete-modal]', function () {
        _modal.submit(this, 'task-delete-modal', function(results) {
            _modal.hide('#task-setting-' + results.task.id);
        });
    });

    /*---- カレンダーの切り替え時 ----*/

    // カレンダーの切り替え時
    $(document).on('click', '.calendar-date', function() {
        var date = $(this).data('date');
        $('#search-form-current').val(date);
        // 非表示処置
        $('.calendar-date.active').removeClass('active');
        $('.calendar-date-detail').addClass('display-none');
        // 表示処理
        $(this).addClass('active');
        $('.calendar-date-detail[data-date=' + date + ']').removeClass('display-none');
    });

    /*---- タスクからカレンダーへのドロップ時 ----*/

    // スケジュールの登録時（ドロップ）
    $(document).on('drop', '.calendar-date', function(e) {
        let dropId = create_id(event.dataTransfer.getData('id'));
        $(this).data('id', $(dropId).data('id'));
        _modal.create(this, 'task-to-schedule-modal', ['id', 'date']);
        $(this).removeData('id');
    });


    /*---- スケジュール保存時 ----*/

    // スケジュールの登録時
    $(document).on('click', '[data-schedule-modal]', function () {
        var startedAt = moment($(create_id($(this).data('schedule-modal') + '-started_at')).val(), 'YYYY/MM/DD HH:mm');
        $('#search-form-current').val(startedAt.format('YYYY-MM-DD'));
        _modal.submit(this, 'schedule-modal');
    });

    /*---- スケジュール削除確認時 ----*/

    // スケジュールの削除確認モーダル
    $(document).on('click', '[data-schedule-confirm-modal]', function () {
        _modal.create(this, 'schedule-confirm-modal', ['id']);
    });

    /*---- スケジュール削除時 ----*/

    // スケジュール削除実行時
    $(document).on('click', '[data-schedule-delete-modal]', function () {
        _modal.submit(this, 'schedule-delete-modal', function(results) {
            _modal.hide('#schedule-setting-' + results.schedule.id);
        });
    });

    /*---- お知らせ完了確認時 ----*/

    // お知らせ完了確認モーダル
    $(document).on('click', '[data-information-complete-confirm-modal]', function (e) {
        _modal.create(this, 'information-complete-confirm-modal', ['id', 'class_name', 'key']);
        // 親要素へのクリックイベント伝搬を回避する
        e.preventDefault();
        e.stopPropagation();
    });

    /*---- お知らせ完了実行時 ----*/

    $(document).on('click', '[data-information-complete-modal]', function () {
        _modal.submit(this, 'information-complete-modal');
    });

    /*---- 修理履歴設定保存時 ----*/

    // 設定保存時
    $(document).on('click', '[data-repair-save-modal]', function () {
        _modal.submit(this, 'repair-save-modal', function(result) {
            if (result.count > 0) {
                $('#inspection-setting-modal #repair-count').html('(' + result.count + ')');
            } else {
                $('#inspection-setting-modal #repair-count').html('');
            }
        }, null, '-form', true);
    });

}

/**
 * ページ読み込み時のイベント設定（再読み込みも実行）
 */
window.dashboard_load_contents = function() {

    /*---- menuの並び替え設定 ----*/

    var menu = $("#dashboard-menu");
    var action = menu.data("action");
    var width = menu.find('li').first().width();
    var data = {
        setting_key: menu.data("setting_key"),
        setting_value: []
    }
    menu.sortable({
        axis: 'x',
        revert: 0.1,
        scroll: true,
        update: function(event, ui){
            data.setting_value = menu.sortable("toArray");
            _ajax.post(action, data, function (results) {
                // 完了メッセージをセットする
                _complete.set(results);
            }, function(xhr, status, errorThrown) {
                _error.omitted(xhr, status, errorThrown);
            });
        },
    }).disableSelection();

    /*---- menuのスクロール制御 ----*/

    var menu_scroll = {
        init: function () {
            $('.btn-scroll').addClass('d-none');
            // scroll bar表示時はボタン表示
            if (menu.get(0)) {
                if (menu.get(0).scrollHeight !== menu.innerHeight()) {
                    $('.btn-scroll').removeClass('d-none');
                }
            }
        }
    }
    //右にスクロール
    $('.btn-next').on('click', function () {
        menu.animate({
            scrollLeft: menu.scrollLeft() + width
        }, 200);
        return false;
    });
    //左スクロール
    $('.btn-prev').on('click', function () {
        menu.animate({
            scrollLeft: menu.scrollLeft() - width
        }, 200);
        return false;
    });
    // 初期スクロールボタン出し分け
    menu_scroll.init();
    // 画面リサイズ時のスクロールボタン出し分け
    $(window).resize(function(){
        menu_scroll.init();
    })

    /*---- お知らせリストの最終項目表示での読み込み処置 ----*/

    // スクロール時
    $('.tab-content').on('scroll', function() {
        var scroll = $(this).scrollTop();
        var height = $(this).outerHeight();
        var scrollHeight = $(this).get(0).scrollHeight;
        // スクロール位置が最終項目まで達した時（100は許容値）
        if (scroll + height + 100 > scrollHeight) {
            let list = $(this).find('.tab-panel.active .information-list');
            let paginator = $(list).find('.infomation-pagenation');
            if ($(paginator).length > 0 && $(paginator).data('loaded') == null) {
                $(paginator).show();
                $(paginator).data('loaded', 'loaded');
                dashboard_information_load(list);
            }
        }
    });

    /*---- お知らせのリスト分最初のページを読み込む ----*/

    // アクティブな項目を読み込み
    $('.dashboard-information .tab-panel.active .information-list').each(function() {
        // 非アクティブな項目はコールバックで読み込む
        dashboard_information_load(this, function() {
            $('.dashboard-information .tab-panel:not(.active) .information-list').each(function() {
                dashboard_information_load(this);
            });
        });
    });

}

/**
 * 設定モーダルのイベント設定
 * （dashboard_data.index.js内でも使用）
 */
window.dashboard_setting_event_set = function () {
    user_setting_event_set();
    vehicle_setting_event_set();
    attendance_setting_event_set();
    rollcall_detail_event_set();
    diagnosis_setting_event_set();
    accident_setting_event_set();
    inspection_setting_event_set();
    guidance_setting_event_set();

    // スケジュールの開始日時変更時
    $(document).on('change', 'input[name=started_at]', function () {
        set_same_time_interval(this, 'input[name=ended_at]');
    });
}
/**
 * お知らせの非同期取得
 * @param {*} selector
 */
window.dashboard_information_load = function(selector, callback = null) {
    let datas = [];
    let action = $(selector).data('action');
    let type = $(selector).data('type');
    let page = $(selector).find('.infomation-pagenation').data('next');
    page = (page == null ? 1 : page);
    datas.push({
        'name': 'type',
        'value': type
    });
    datas.push({
        'name': 'page',
        'value': page
    });
    _ajax.background(action, datas, function (results) {
        $(selector).find('.infomation-pagenation').remove();
        $(selector).append(results);
        let warningCount = $('#information-'+type+'-warning').val();
        let alertCount = $('#information-'+type+'-alert').val();
        let badgeWarning = create_id($(selector).data('badge_id') + '_warning');
        let badgeAlert = create_id($(selector).data('badge_id') + '_alert');
        if (warningCount > 0 || alertCount > 0){
            $(selector).find('.information-not-found').toggleClass('display-none', true);
        } else {
            $(selector).parent().find('.information-not-found').toggleClass('display-none', false);
        }
        if (warningCount > 100){
            $(badgeWarning).text('100+');
        } else if(warningCount > 0){
            $(badgeWarning).text(warningCount);
        }
        if (alertCount > 100){
            $(badgeAlert).text('100+');
        } else if(alertCount > 0){
            $(badgeAlert).text(alertCount);
        }
        $(selector).data('page', page);
        initialize();
        if (callback != null) {
            callback();
        }
    }, function(xhr, status, errorThrown) {
        _error.omitted(xhr, status, errorThrown);
    }, 'html');
}
