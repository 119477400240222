/**
 * 時分入力（24時間以上）フォームを初期化
 */
window.initialize_input = function() {

    /*---- 半角入力フォームの初期化 ----*/

    $(document).on('compositionend', '.harf-char-input', function(e) {
        let half = $(this).val().replace(/[！-～]/g, function(str) {
            return String.fromCharCode(str.charCodeAt(0) - 0xFEE0);
        }).replace(/”/g, "\"")
        .replace(/’/g, "'")
        .replace(/‘/g, "`")
        .replace(/￥/g, "\\")
        .replace(/　/g, " ")
        .replace(/〜/g, "~");
        $(this).val(half);
    });

}
/**
 * ツールチップを初期化
 */
window.initialize_tooltip = function() {
    // ツールチップを初期化
    $('[data-toggle="tooltip"]').tooltip();
}
/**
 * select2を初期化
 */
window.initialize_select2 = function() {
    // select2を初期化
    $('select.select2').each(function() {
        let noResults = $(this).data('no-results');
        // modal内の時は親modal要素をdropdownParent指定する
        let parent = $(this).parents('.modal-content');
        if (parent.length > 0) {
            $(this).select2({
                width: "100%",
                language: { "noResults": function() {
                        return noResults;
                    }
                },
                dropdownParent: parent
            });
        } else {
            $(this).select2({
                width: "100%",
                language: { "noResults": function() {
                        return noResults;
                    }
                },
            });
        }
    });
    // ツールバーの所属セレクト用の初期化処理
    $('.affiliation-select select').each(function () {
        let noResults = $(this).data('no-results');
        let placeholder = $(this).data('placeholder');
        let dropdownClass = $(this).data('dropdown-class');
        $(this).select2({
            dropdownAutoWidth: true,
	        width: 'auto',
            language: { "noResults": function() { return noResults;}},
            placeholder: placeholder,
            dropdownCssClass: dropdownClass
        });
    });
    // 親の更新処理
    let updatedParentSelects = [];
    function parentSelectUpdate(elm, selector) {
        const parent = $(elm).parents('form').find(selector);
        if (parent.length > 0 && $(elm).val() == '' && $(parent).val() != '') {
            // parent内に複数の要素がある可能性を考慮してmapでhtmlを取得
            const parentKey = parent.toArray().map(item => item.outerHTML).join(',');
            // 重複判定
            if (!updatedParentSelects.includes(parentKey)) {
                $(parent).trigger('change');
                // changeした要素を配列に格納
                updatedParentSelects.push(parentKey);
            }
        }
        // 短時間内の同じ要素へのchange多重実行を防ぐため一定時間経過後に配列をリセット
        setTimeout(function() {
            updatedParentSelects = [];
        }, 100);
    }
    // nullで保存している場合の初期値の取得処理（親がform内に存在している場合に更新を行う）
    $('select.company-select').each(function() {
        parentSelectUpdate(this, 'select.holding-select');
    });
    $('select.section-select').each(function() {
        parentSelectUpdate(this, 'select.company-select');
    });
    $('select.user-select').each(function() {
        parentSelectUpdate(this, 'select.section-select');
    });
    $('select.driver-select').each(function() {
        parentSelectUpdate(this, 'select.section-select');
    });
    $('select.company-driver-select').each(function() {
        parentSelectUpdate(this, 'select.company-select');
    });
    $('select.account-user-select').each(function() {
        parentSelectUpdate(this, 'select.section-select');
    });
    $('select.rollcall-assistant-select').each(function() {
        parentSelectUpdate(this, 'select.section-select');
    });
    $('select.rollcall-enforcer-select').each(function() {
        parentSelectUpdate(this, 'select.section-select');
    });
    $('select.rollcall-authorizer-select').each(function() {
        parentSelectUpdate(this, 'select.section-select');
    });
    $('select.rollcall-company-authorizer-select').each(function() {
        parentSelectUpdate(this, 'select.company-select');
    });
    $('select.rollcall-recognized-select').each(function() {
        parentSelectUpdate(this, 'select.section-select');
    });
    $('select.rollcall-general-select').each(function() {
        parentSelectUpdate(this, 'select.section-select');
    });
    $('select.maintenance-manager-select').each(function() {
        parentSelectUpdate(this, 'select.section-select');
    });
    $('select.maintenance-assistant-select').each(function() {
        parentSelectUpdate(this, 'select.section-select');
    });
    $('select.maintenance-manager-and-assistant-select').each(function() {
        parentSelectUpdate(this, 'select.section-select');
    });
    $('select.company-maintenance-manager-and-assistant-select').each(function() {
        parentSelectUpdate(this, 'select.company-select');
    });
    $('select.vehicle-select').each(function() {
        parentSelectUpdate(this, 'select.section-select');
    });
    $('select.company-vehicle-select').each(function() {
        parentSelectUpdate(this, 'select.company-select');
    });
    /**
     * 項目が増え続けるため、以下data付与で更新処理を実行
     *  (以下、会社のセレクトを更新する「select.company-select」のサンプル)
     * 
     * @uses {{ Form::select('label', 'name', $value, [
     *      'id' => 'オリジナルID',
     *      'class' => 'set-company-select-option オリジナルクラス',
     *      'data-parent-update' => 'select.company-select',
     *      'data-action' => route('オプション取得先ルーティング名')
     * ]) }}
     */
    $('select[data-parent-update]').each(function() {
        const target = $(this).data('parent-update');
        parentSelectUpdate(this, target);
    });
    
}
/**
 * マルチセレクトを初期化
 */
window.initialize_multiselect = function() {
     // multi-selectで初期化のタイミングと、件数が一定を超えると高さが取得できずに、スクロールできなくなる為、件数が多い場合は初期化を遅らせる
     // https://github.com/wenzhixin/multiple-select/issues/552
    let initializeAfterShow = 199;

    // マルチセレクトを初期化
    $('select.multi-select:not([initialized=true])').each(function() {
        let elm = $(this);
        let id = elm.attr('id');

        // マルチセレクトを初期化
        function initializedTrue(elm) {
            let noResults = elm.data('no-results');
            let countSelected = elm.data('count-selected');
            let allSelect = elm.data('all-select');
            let allSelected = elm.data('all-selected');
            elm.multipleSelect({
                formatSelectAll: function () {
                    return allSelect;
                },
                formatAllSelected: function () {
                    return allSelected;
                },
                formatNoMatchesFound: function () {
                    return noResults;
                },
                formatCountSelected: function (count, total) {
                    return countSelected.replace(':count', count).replace(':total', total);
                },
                onOpen: function () {
                    // Account/setting()権限グループ　モーダルスクロールのロック対応
                    $('.privilege_group_lock_avoidance').css('min-height', '100px');
                },
            });
            elm.attr('initialized', 'true');
        }

        let optionLength = elm.children('option').length;
        // 選択肢件数が199件以上の場合は、要素表示タイミングで初期化
        if ((optionLength >= initializeAfterShow) && id) {
            // 監視対象の要素は、multiple-selectが配置されている親フォーム
            let target = document.getElementById(id).closest('form');
            let observer = new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {
                    // 親フォーム要素が表示されたら初期化
                    if (entry.isIntersecting) {
                        initializedTrue(elm); // 初期化
                        observer.unobserve(entry.target); // 監視を解除
                    }
                });
            });
            // 対象要素の監視を開始
            observer.observe(target);
        } else {
            // 初期化
            initializedTrue(elm);
        }
    });

    function allotSelect(btn, from, to) {
        
        const selectFromName = from + '_select';
        const selectToName = to + '_select';
        $('select[name=' + selectFromName + '] option:selected').each(function(i, e) {
            $('select[name=' + selectToName + ']').append($(e));
            const value = $('[data-name=' + from + '] input[value=' + $(e).val() + ']');
            $(value).attr('name', to + '[]');
            $('[data-name=' + to + ']').append($(value));
        });
    }

    $(document).off('click', '.allot-form .to-right-btn');
    $(document).on('click', '.allot-form .to-right-btn', function() {
        const left = $(this).parents('.allot-form').find('.left-selected').data('name');
        const right = $(this).parents('.allot-form').find('.right-selected').data('name');
        allotSelect(this, left, right);
    });

    $(document).off('click', '.allot-form .to-left-btn');
    $(document).on('click', '.allot-form .to-left-btn', function() {
        const left = $(this).parents('.allot-form').find('.left-selected').data('name');
        const right = $(this).parents('.allot-form').find('.right-selected').data('name');
        allotSelect(this, right, left);
    });
}
/**
 * デートピッカーを初期化
 * @param {*} type all, time-minutes, time-seconds, date, datetime, datetime-seconds, month, year
 */
window.initialize_datepicker = function(type = 'all') {

    /*---- 時分選択フォームの初期化 ----*/

    if (type == 'all' || type == 'time-minutes') {
        $('.time-minutes-picker').each(function() {
            var display_viewMode = ($(this).data('display-viewmode') ? $(this).data('display-viewmode') : 'times');
            let minuteStepping = ($(this).data('minute-stepping') ? $(this).data('minute-stepping') : 1);
            $(this).datetimepicker({
                datepicker: false,
                format: 'HH:mm',
                viewMode: display_viewMode,
                stepping: minuteStepping,
                locale: 'ja',
                icons: {
                    time: 'far fa-clock',
                }
            });
            $(this).off('focus');
            $(this).on('focus', function() {
                $(this).datetimepicker('show');
            });
            $(this).off('blur');
            $(this).on('blur', function() {
                $(this).datetimepicker('hide');
            });
            $(this).off('change.datetimepicker');
            $(this).on('change.datetimepicker', function(e){
                let id = create_id($(this).data('id'));
                if ($(this).data('type') == 'int') {
                    let values = $(this).val().split(':');
                    let minutes = (Number(values[0]) * 60) + (Number(values[1]));
                    $(id).val(minutes);
                } else {
                    $(id).val($(this).val());
                }
            });
        });
    }

    /*---- 時間選択フォームの初期化 ----*/

    if (type == 'all' || type == 'time-seconds') {
        $('.time-seconds-picker').each(function() {
            var display_viewMode = ($(this).data('display-viewmode') ? $(this).data('display-viewmode') : 'times');
            let minuteStepping = ($(this).data('minute-stepping') ? $(this).data('minute-stepping') : 1);
            $(this).datetimepicker({
                datepicker: false,
                format: 'H:mm:ss',
                viewMode: display_viewMode,
                stepping: minuteStepping,
                locale: 'ja',
                icons: {
                    time: 'far fa-clock',
                }
            });
            $(this).off('focus');
            $(this).on('focus', function() {
                $(this).datetimepicker('show');
            });
            $(this).off('blur');
            $(this).on('blur', function() {
                $(this).datetimepicker('hide');
            });
            $(this).off('change.datetimepicker');
            $(this).on('change.datetimepicker', function(e){
                let id = create_id($(this).data('id'));
                if ($(this).data('type') == 'int') {
                    let values = $(this).val().split(':');
                    let seconds = (Number(values[0]) * 60 * 60) + (Number(values[1]) * 60) + Number(values[2]);
                    $(id).val(seconds);
                } else {
                    $(id).val($(this).val());
                }
            });
        });
    }

    /*---- 日付選択フォームの初期化 ----*/

    if (type == 'all' || type == 'date') {
        $('.date-picker').each(function() {
            let defaultDate = $(this).val();
            var display_viewMode = ($(this).data('display-viewmode') ? $(this).data('display-viewmode') : 'days');
            $(this).datetimepicker({
                maxDate: $(this).data('max-date') ? $(this).data('max-date') : false,
                minDate: $(this).data('min-date') ? $(this).data('min-date') : false,
                format: 'YYYY/MM/DD',
                viewMode: display_viewMode,
                locale: 'ja',
                dayViewHeaderFormat: 'YYYY年 MM月',
                icons: {
                    time: 'far fa-clock',
                }
            });
            $(this).val(defaultDate);
            $(this).off('focus');
            $(this).on('focus', function() {
                // 値を取得
                let value = $(this).val();
                $(this).datetimepicker('show');
                // 元の値が空欄の場合はカレンダーの表示時に値を与えない
                if (value == '') {
                    $(this).val('')
                }
            });
            $(this).off('blur');
            $(this).on('blur', function() {
                $(this).datetimepicker('hide');
            });
            $(this).off('change.datetimepicker');
            $(this).on('change.datetimepicker', function(e){
                // 変更前の値がない場合かつ変更されている場合にイベントを発行
                if (e.oldDate != null && e.oldDate != e.date) {
                    $(this).trigger('change');
                }
            });
        });
    }

    /*---- 日時選択フォームの初期化 ----*/

    if (type == 'all' || type == 'datetime') {
        $('.datetime-picker').each(function() {
            var displayViewMode = ($(this).data('display-viewmode') ? $(this).data('display-viewmode') : 'days');
            let minuteStepping = ($(this).data('minute-stepping') ? $(this).data('minute-stepping') : 1);
            let horizontal = $(this).data('horizontal') ? $(this).data('horizontal') : 'auto';
            let vertical = $(this).data('vertical') ? $(this).data('vertical') : 'auto';
            let widgetParent = $(this).data('widget-parent') ? $(this).data('widget-parent') : null;
            let defaultDate = $(this).val();
            let setTimeout = $(this).data('delay-display') ? $(this).data('delay-display') : 0
            $(this).datetimepicker({
                widgetParent: widgetParent,
                maxDate: $(this).data('max-date') ? $(this).data('max-date') : false,
                minDate: $(this).data('min-date') ? $(this).data('min-date') : false,
                format: 'YYYY/MM/DD HH:mm',
                viewMode: displayViewMode,
                stepping: minuteStepping,
                locale: 'ja',
                dayViewHeaderFormat: 'YYYY年 MM月',
                icons: {
                    time: 'far fa-clock',
                },
                defaultViewDate: '2000/05/05 00:00',
                widgetPositioning: {
                    horizontal: horizontal,
                    vertical: vertical
                }
            });
            $(this).val(defaultDate);
            $(this).off('focus');
            $(this).on('focus', function() {
                // 値を取得
                let value = $(this).val();
                let defaultDate = $(this).data('default-date');
                if ($(this).val() == '' && typeof defaultDate !== undefined) {
                    $(this).val(defaultDate);
                }
                // 元の値が空欄の場合はカレンダーの表示時に値を与えない
                if (value == '') {
                    $(this).val('');
                }
                let targetElement = $(this);
                window.setTimeout(function() {
                    targetElement.datetimepicker('show');
                    if (value == '') {
                        targetElement.val('');
                    }
                }, setTimeout);
            });
            $(this).off('blur');
            $(this).on('blur', function(e) {
                $(this).datetimepicker('hide');
            });
            $(this).off('change.datetimepicker');
            $(this).on('change.datetimepicker', function(e){
                // 変更前の値がない場合かつ変更されている場合にイベントを発行
                if (e.oldDate != null && e.oldDate != e.date) {
                    $(this).data('old-timestamp', e.oldDate.unix());
                    $(this).trigger('change');
                }
            });
        });
    }

    /*---- 日時（秒）選択フォームの初期化 ----*/

    if (type == 'all' || type == 'datetime-seconds') {
        $('.datetime-seconds-picker').each(function() {
            let defaultDate = $(this).val();
            var display_viewMode = ($(this).data('display-viewmode') ? $(this).data('display-viewmode') : 'days');
            let minuteStepping = ($(this).data('minute-stepping') ? $(this).data('minute-stepping') : 1);
            $(this).datetimepicker({
                maxDate: $(this).data('max-date') ? $(this).data('max-date') : false,
                minDate: $(this).data('min-date') ? $(this).data('min-date') : false,
                format: 'YYYY/MM/DD HH:mm:ss',
                viewMode: display_viewMode,
                stepping: minuteStepping,
                locale: 'ja',
                dayViewHeaderFormat: 'YYYY年 MM月',
                icons: {
                    time: 'far fa-clock',
                }
            });
            $(this).val(defaultDate);
            $(this).off('focus');
            $(this).on('focus', function() {
                // 値を取得
                let value = $(this).val();
                let defaultDate = $(this).data('default-date');
                if ($(this).val() == '' && typeof defaultDate !== undefined) {
                    $(this).val(defaultDate);
                }
                $(this).datetimepicker('show');
                // 元の値が空欄の場合はカレンダーの表示時に値を与えない
                if (value == '') {
                    $(this).val('')
                }
            });
            $(this).off('blur');
            $(this).on('blur', function(e) {
                $(this).datetimepicker('hide');
            });
            $(this).off('change.datetimepicker');
            $(this).on('change.datetimepicker', function(e){
                // 変更前の値がない場合かつ変更されている場合にイベントを発行
                if (e.oldDate != null && e.oldDate != e.date) {
                    $(this).data('old-timestamp', e.oldDate.unix());
                    $(this).trigger('change');
                }
            });
        })
    }

    /*---- 月選択フォームの初期化 ----*/

    if (type == 'all' || type == 'month') {
        $('.month-picker').each(function() {
            var display_format = ($(this).data('display-format') ? $(this).data('display-format') : 'M');
            var name = $(this).data('target-name');
            // month-pickerは2つのinputから成る（ループ対象の<input data-target-name>, 実際使用するname属性のある<input hidden name>）
            // nameの配列構造(name="name[]")に対応する為、ループ対象の$('.month-picker')に隣接(next)するinput要素を指定
            var input = $(this).next('input[name="' + name + '"]');
            if (input.val() != '') {
                $(this).val(moment(input.val()).format(display_format));
            }
            $(this).off('changeDate');
            $(this).off('blur');
            $(this).datepicker({
                format: display_format.toLowerCase(),
                language: 'ja',
                autoclose: true,
                minViewMode: 'months',
            }).on('change', function() {
                $(this).datepicker('hide');
                const val = (($(this).val() != '') ? moment($(this).val(), 'YYYY/MM').format('YYYY-MM') : '');
                if (input.val() != val) {
                    input.val(val).trigger('change');
                }
            }).on('blur', function(e) {
                if ($(this).parents('.month-picker-inputs').hasClass('icons')) {
                    if (input.val() != '') {
                        $(this).val(moment(input.val()).format(display_format));
                    }
                } else {
                    if ($(this).val() == '') {
                        input.val('');
                    } else {
                        $(this).val(moment(input.val()).format(display_format));
                    }
                }
            });
        });
        $('.month-picker-inputs a.back').off('click');
        $('.month-picker-inputs a.back').on('click', function () {
            month_picker_inputs_action(this, 'subtract');
        });
        $('.month-picker-inputs a.forward').off('click');
        $('.month-picker-inputs a.forward').on('click', function () {
            month_picker_inputs_action(this, 'add');
        });
        $('.month-picker-inputs a.current').off('click');
        $('.month-picker-inputs a.current').on('click', function () {
            month_picker_inputs_action(this, 'current');
        });
        function month_picker_inputs_action(elm, type)
        {
            let picker = $(elm).parent().find('.month-picker');
            let input = $(elm).parent().find('.month-picker-value');
            let display_format = ($(picker).data('display-format') ? $(picker).data('display-format') : 'M');
            let value = input.val();
            let update = moment();
            switch(type) {
                case 'subtract':
                    update = moment(value).subtract(1, 'months');
                    break;
                case 'add':
                    update = moment(value).add(1, 'months');
                    break;
            }
            $(elm).parent().find('.month-picker').val(update.format(display_format));
            $(elm).parent().find('.month-picker-value').val(update.format('YYYY-MM')).trigger('change');
        }
    }

    /*---- 年選択フォームの初期化 ----*/

    if (type == 'all' || type == 'year') {
        $('.year-picker').each(function() {
            var display_format = ($(this).data('display-format') ? $(this).data('display-format') : 'YYYY');
            var name = $(this).data('target-name');
            $(this).val(moment($('input[name=' + name + ']').val()).format(display_format));
            $(this).off('changeDate');
            $(this).off('blur');
            $(this).datepicker({
                format: display_format.toLowerCase(),
                language: 'ja',
                autoclose: true,
                minViewMode: 'years',
            }).on('changeDate', function(e) {
                $('input[name=' + name + ']').val(moment(e.dates[0]).format('YYYY'));
            }).on('blur', function(e) {
                $(this).val(moment($('input[name=' + name + ']').val()).format(display_format));
            });
        });
        $('.year-picker-inputs a.back').off('click');
        $('.year-picker-inputs a.back').on('click', function () {
            year_picker_inputs_action(this, 'subtract');
        });
        $('.year-picker-inputs a.forward').off('click');
        $('.year-picker-inputs a.forward').on('click', function () {
            year_picker_inputs_action(this, 'add');
        });
        $('.year-picker-inputs a.current').off('click');
        $('.year-picker-inputs a.current').on('click', function () {
            year_picker_inputs_action(this, 'current');
        });
        function year_picker_inputs_action(elm, type)
        {
            let picker = $(elm).parent().find('.year-picker');
            let input = $(elm).parent().find('.year-picker-value');
            let display_format = ($(picker).data('display-format') ? $(picker).data('display-format') : 'YYYY');
            let value = input.val();
            let update = moment();
            switch(type) {
                case 'subtract':
                    update = moment(value).subtract(1, 'years');
                    break;
                case 'add':
                    update = moment(value).add(1, 'years');
                    break;
            }
            $(elm).parent().find('.year-picker').val(update.format(display_format));
            $(elm).parent().find('.year-picker-value').val(update.format('YYYY')).trigger('change');
        }
    }


}
/**
 * switchフォームを初期化
 */
window.initialize_switch_form = function() {
    // スイッチフォームを初期化
    $('.switch-form').each(function() {
        switch_form_action(this);
        $(document).off('change', '.switch-form');
        $(document).on('change', '.switch-form', function() {
            switch_form_action(this);
        });
    });
    // スイッチ変更時の処理
    function switch_form_action(elm) {
        let selector = $(elm).data('disabled-selector');
        if ($(selector).length > 0) {
            if ($(elm).prop('checked') && $(elm).val() === '1') {
                $(selector).val($(selector).data('default')).prop('disabled', false);
            } else {
                $(selector).val('').prop('disabled', true);
            }
        }
    }
}

/**
 * Flagフォームを初期化
 */
window.initialize_switchflag = function() {
    // スイッチフォームを初期化
    $('.switch-flag').each(function() {
        switch_flag_action(this);
        $(document).off('change', '.switch-flag');
        $(document).on('change', '.switch-flag', function() {
            switch_flag_action(this);
        });
    });
    // スイッチ変更時の処理
    function switch_flag_action(elm) {
        let selector = $(elm).data('disabled');
        $(selector).toggleClass('display-none', !$(elm).prop('checked'))
    }
}
/**
 * ソート可能リストの初期化
 */
window.initialize_sortable = function() {
    // ソート可能なリストを初期化
    $('.sortable').sortable();
}
/**
 * DnDアップローダーの初期化
 */
window.initialize_dnd_upload = function() {
    $('[data-file-dropzone]').each(function() {
        let dropzone = $(this).data('file-dropzone');
        let dropzoneId = create_id($(this).data('file-dropzone'));
        let input = this;
        $(document).off('dragover', dropzoneId);
        $(document).on('dragover', dropzoneId, function(e) {
            e.stopPropagation();
            e.preventDefault();
            $(this).toggleClass('file-dnd-upload', true);
        });
        $(document).off('dragleave', dropzoneId);
        $(document).on('dragleave', dropzoneId, function(e) {
            e.stopPropagation();
            e.preventDefault();
            $(this).toggleClass('file-dnd-upload', false);
        });
        document.getElementById(dropzone).addEventListener('drop', function(e) {
            e.stopPropagation();
            e.preventDefault();
            input.files = e.dataTransfer.files;
            $(input).trigger('change');
        }, false);
    })
}
/**
 * 共通の禁止操作や初期化処理などを設定する。
 * @param {*} type all, tooltip, select2, multiselect, datepicker
 */
window.initialize = function (type = 'all') {

    /*---- 入力系フォームの初期化 ----*/

    if (type == 'all' || type == 'input') {
        initialize_input();
    }

    /*---- ツールチップの有効化 ----*/

    if (type == 'all' || type == 'tooltip') {
        initialize_tooltip();
    }

    /*---- セレクト２を初期化 ----*/

    if (type == 'all' || type == 'select2') {
        initialize_select2();
    }

    /*---- マルチセレクトを初期化 ----*/

    if (type == 'all' || type == 'multiselect') {
        initialize_multiselect();
    }

    /*---- 時分選択フォームの初期化 ----*/

    if (type == 'all' || type == 'datepicker') {
        initialize_datepicker('time-minutes');
    }

    /*---- 時間選択フォームの初期化 ----*/

    if (type == 'all' || type == 'datepicker') {
        initialize_datepicker('time-seconds');
    }

    /*---- 日付選択フォームの初期化 ----*/

    if (type == 'all' || type == 'datepicker') {
        initialize_datepicker('date');
    }

    /*---- 日時選択フォームの初期化 ----*/

    if (type == 'all' || type == 'datepicker') {
        initialize_datepicker('datetime');
    }

    /*---- 日時（秒）選択フォームの初期化 ----*/

    if (type == 'all' || type == 'datepicker') {
        initialize_datepicker('datetime-seconds');
    }

    /*---- 月選択フォームの初期化 ----*/

    if (type == 'all' || type == 'datepicker') {
        initialize_datepicker('month');
    }

    /*---- 年選択フォームの初期化 ----*/

    if (type == 'all' || type == 'datepicker') {
        initialize_datepicker('year');
    }

    /*---- スイッチフォームの初期化 ----*/

    if (type == 'all' || type == 'switch_form') {
        initialize_switch_form();
    }

    /*---- Flagフォームの初期化 ----*/

    if (type == 'all' || type == 'switchflag') {
        initialize_switchflag();
    }

    /*---- ソート可能リストの初期化 ----*/

    if (type == 'all' || type == 'sortable') {
        initialize_sortable();
    }

    /*---- DnDアップロード ----*/

    if (type == 'all' || type == 'dnd_upload') {
        initialize_dnd_upload();
    }

    /*---- その他の制御 ----*/

    if (type == 'all') {

        /*---- フォームの直接のサブミットを禁止 ----*/

        $(document).off('submit', 'form:not([data-force-send=true])');
        $(document).on('submit', 'form:not([data-force-send=true])', function() {
            return false;
        });

        /*---- backdropクリックで閉じないように修正 ----*/

        $.each($('.modal'), function() {
            if ($(this).data('backdrop') == null) {
                $(this).data('backdrop', 'static');
            }
        });

        /*---- モーダルの表示時にスクロールをトップに変更 ----*/

        $(document).off('shown.bs.modal', '.modal');
        $(document).on('show.bs.modal', '.modal', function (e) {
            // bootstrapとdatepickerでshown.bs.modalが競合する為、発生元によって処理を分ける
            if (!e.target.classList.contains('month-picker') && !e.target.classList.contains('year-picker')) {
                // month-picker以外からのイベント発生であればスクロールする(month-picker要素clickでトップにスクロールされるのを防ぐ)
                $(this).find('.modal-body').scrollTop(0);
            }
        });

        /*---- モーダルの重複表示 ----*/

        $(document).on('show.bs.modal', '.modal', function (e) {
            var currentModal = $(e.currentTarget);
            var zIndex = 1040 + (10 * $('.modal:visible').length);
            currentModal.css('z-index', zIndex);
            setTimeout(function() {
                $('.modal-backdrop')
                    .not('.modal-stack')
                    .css('z-index', zIndex - 1)
                    .addClass('modal-stack');
            }, 0);
        });

        /*---- フォーム操作時のフォーム外へのtab移動制限 ----*/

        $(document).off('keydown', 'form');
        $(document).on('keydown', 'form', function (e) {
            if (e.key == 'Tab') {
                let inputs = $(this).find('input:visible, textarea:visible, select:visible, button:visible').not(':disabled');
                let focused = $(this).find(':focus')[0];
                for(i = 0; i < inputs.length; i ++) {
                    if (focused == inputs[i]) {
                        break;
                    }
                }
                if (e.shiftKey) {
                    i --;
                } else {
                    i ++;
                }
                if (i >= inputs.length) {
                    e.preventDefault();
                    $(inputs[0]).trigger('focus');
                } else if (i < 0) {
                    e.preventDefault();
                    $(inputs[inputs.length - 1]).trigger('focus');
                }
            }
        });
    }
}
/**
 * 各ページ表示時に呼出
 */
$(function () {
    //コンテンツの読込
    load_contents();
});
